@font-face{font-family: "Rubik", sans-serif;font-style: normal;font-weight: 400;src: url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,600,900);}
html{-webkit-box-sizing:border-box;box-sizing:border-box; }
*, *::before, *::after{-webkit-box-sizing:inherit;box-sizing:inherit; }
::-moz-selection{background:rgba(125, 188, 255, 0.6); }
::selection{background:rgba(125, 188, 255, 0.6); }
:focus{outline: none;outline-offset: 2px;-moz-outline-radius: 6px;}
body{text-transform:none;line-height:1.28581;letter-spacing:0;font-family:Rubik,sans-serif;font-size:14px;font-weight:400;color:#727272}
@-ms-viewport{width:device-width;}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section{display:block;}
p{margin-top:0;margin-bottom:10px;font-size:16px;font-family: "Rubik", sans-serif;color: #727272;letter-spacing: 0;font-weight: normal;}
ul{list-style-type:none;}
small{font-size:12px;}
strong{font-weight:600;}
blockquote{margin:0 0 1rem;}
ol,ul,dl{margin-top:0;margin-bottom:1rem;}
ol ol,ul ul,ol ul,ul ol{margin-bottom:0;}
sub,sup{position:relative;font-size:75%;line-height:0;vertical-align:baseline;}
sub{bottom:-.25em;}
sup{top:-.5em;}
ul.nav{display:-webkit-box;}
.min-height{min-height: calc(100vh - 350px)};
/************************ Color Pallate ***********************/
.bg-darkblue,.bg-darkgrey,.bg-green,.bg-grey,.bg-greyis,.bg-deepcyan,.bg-primary,.bg-red,.bg-white,.bg-yellow{border:4px solid rgba(205,205,205,.2)}
.bg-primary{background:#236AEE}
.bg-darkgrey{background:#383838}
.bg-grey{background:#727272}
.bg-greyis{background:#CBCBCB}
.bg-deepcyan{background:#00BCD4;border:4px solid rgba(205,205,205,.2)}
.bg-white{background:#FFF}
.bg-darkblue{background:#0C4CC1}
.bg-yellow{background:#F5A623}
.bg-red{background:#FF5252}
.bg-green{background:#62A31B}
.bg-crystal{background:#E3F2FD}
.bg-lightcrystal{background:#F7FBFF}

/********************* Types of shadows *******************/
.bp3-elevation-1{background:#FFF;box-shadow:0 2px 13px 0 rgba(204,204,204,.5);-webkit-box-shadow:0 2px 13px 0 rgba(204,204,204,.5)}
.bp3-elevation-2{background:#FFF;box-shadow:0 2px 13px 0 rgba(243,243,243,.5)!important;-webkit-box-shadow:0 2px 13px 0 rgba(243,243,243,.5)!important}
.bp3-elevation-3{background:#FFF;box-shadow:0 0 16px 0 rgba(210,210,210,.5)!important;-webkit-box-shadow:0 0 16px 0 rgba(210,210,210,.5)!important}
.bp3-elevation-4{background:#FFF;box-shadow:0 2px 13px 0 rgba(204,204,204,.5)!important;-webkit-box-shadow:0 2px 13px 0 rgba(204,204,204,.5)!important}
.bp3-elevation-5{background:#FFF;box-shadow:0 2px 24px 0 #F1F1F1!important;-webkit-box-shadow:0 2px 24px 0 #F1F1F1!important}

/********************* Types of shadows *******************/
.color-gradient1{background-image:linear-gradient(45deg,#49A3F8 0,#0548C4 100%)}
.color-gradient2{background-image:linear-gradient(-134deg,#FF5252 0,#FAD25C 100%)}
.color-gradient3{background-image:linear-gradient(-134deg,#2413C5 0,#C86DD7 100%)}
.color-gradient4{background-image:linear-gradient(-135deg,#F42E78 0,#C17AFC 100%);border-radius:4px}
.color-gradient5{background-image:linear-gradient(-135deg,#368FFE 0,#27DFC5 100%);border-radius:4px}
.color-gradient6{background-image:linear-gradient(-135deg,#FF8993 0,#FEC180 100%);border-radius:4px}
.color-gradient7{background-image:linear-gradient(42deg,#F4E5FA 0,#61A2E7 100%);border-radius:4px}
.color-gradient8{background-image:linear-gradient(44deg,#BBD534 0,#00A88D 100%);border-radius:4px}
.color-gradient9{background-image:linear-gradient(-135deg,#6188ED 0,#7E4AAD 100%);border-radius:4px}

/********************* Heading *******************/
h1, h2, h3, h4, h5, h6{font-weight:400;}
.bp3-heading{font-family:Rubik,sans-serif;color:#383838;font-weight:400;margin:0 0 10px;padding:0;letter-spacing:0}
.bp3-running-text h1,h1.bp3-heading{line-height:30px;font-size:30px}
.bp3-running-text h2,h2.bp3-heading{line-height:26px;font-size:28px}
.bp3-running-text h3,h3.bp3-heading{line-height:25px;font-size:26px}
.bp3-running-text h4,h4.bp3-heading{line-height:21px;font-size:24px}
.bp3-running-text h5,h5.bp3-heading{line-height:19px;font-size:20px}

/********************* Paragraph *******************/
p{font-family:Rubik,sans-serif;color:#727272;letter-spacing:0;font-weight:400}
.bp3-text-xlarge{font-size:18px;color:#727272}
.bp3-text-large{font-size:16px!important}
.bp3-text-medium{font-size:14px!important}
.bp3-text-small{font-size:12px!important}
.bp3-text-xsmall{font-size:10px!important}
.bp3-rtl{text-align:right}

/******************************* Buttons ******************************/
.bp3-button:not([class*=bp3-intent-]){box-shadow:none;background-color:#fff;background-image:none;color:#383838;border-radius:2px}
.bp3-button:not([class*=bp3-intent-]):hover{color:#fff !important;}
.bp3-button:not([class*=bp3-intent-]):hover{box-shadow:none;background:#236AEE;border-color:#236AEE;background-image:linear-gradient(to bottom,#236AEE,#236AEE)}
.bp3-button,.bp3-button:active,.bp3-button:focus,.bp3-button:hover{transition:.2s;-webkit-transition:.2s;-moz-transition:.2s;-ms-transition:.2s;-o-transition:.2s}
a.bp3-button{text-align:center;text-decoration:none;-webkit-transition:none;transition:none}
.bp3-button{display:inline-block;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;padding:.375rem 1.5rem;font-size:16px;line-height:1.5;border-radius:2px;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out}
.bp3-button.bp3-intent-primary{background:#236AEE;color:#FFF;border-color:#236AEE;box-shadow:none}
.bp3-button.bp3-intent-primary:hover{color:#fff;border-color:#236AEE}
.bp3-button.bp3-intent-primary.bp3-active,.bp3-button.bp3-intent-primary.bp3-active:hover,.bp3-button.bp3-intent-primary:active,.bp3-button.bp3-intent-primary:hover{background-color:#0062cc;background-image:none;box-shadow:none}
.bp3-button.bp3-intent-primary:focus{outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25);transition:.2s;-webkit-transition:.2s}
.bp3-elevation-2{box-shadow:0 4px 4px rgba(0,0,0,.2)}
.bp3-button.bp3-small,.bp3-small .bp3-button{border-radius:4px;font-size:14px;text-align:center;padding:.25rem .6rem;box-shadow:none}
.bp3-button.bp3-large,.bp3-large .bp3-button{padding:1rem 2.7rem;text-transform:capitalize;border-radius:2px;letter-spacing:0;font-size:14px}
.bp3-button.bp3-fill{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%}
.bp3-button.bp3-secondary{border:#ECECEC;color:#383838;background-color:#ECECEC;box-shadow:none}
.bp3-button.bp3-secondary:hover{color:#fff;background-color:#0069d9;border-color:#0062cc;box-shadow:none}
.bp3-intent-secondary:hover{background:#236AEE;color:#fff}
.bp3-outline{color:#236AEE;background-color:transparent;background-image:none;border-color:#979797}
.bp3-outline:hover{color:#fff}
.bp3-outline span.bp3-button-text{color:#236AEE}
.bp3-button .bp3-outline:hover,.bp3-outline:hover span.bp3-button-text{color:#fff!important}
.bp3-button.bp3-intent-danger{box-shadow:none;background-color:#FF5252;background-image:none;color:#fff}
.bp3-button.bp3-intent-danger:hover{box-shadow:none;background-color:#DC3B3B}

/******************************* Dropdown Menu************************/
.bp3-button[class*=bp3-icon-]::before{line-height:1;font-family:Icons16,sans-serif;font-size:16px;font-weight:400;font-style:normal;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;color:#5c7080;float:right}
.bp3-button::before,.bp3-button>*{margin-top:3px}
.bp3-menu-item.bp3-active, .bp3-menu-item:active{background-color:#236AEE;color:#fff;}
.bp3-menu-item{font-size:14px;color:#383838;letter-spacing:0;line-height:20px;padding:6px 15px;}
.dropdown_menu .bp3-popover{background:#FFF;border:1px solid #CBCBCB;border-radius:4px;box-shadow:none !important;-webkit-transform:scale(1);transform:scale(1);display:inline-block;z-index:20}
/*.dropdown-menu{margin-top:.7em;z-index:1000;width:180px;padding:8px 0;list-style:none;text-align:left;color:#182026;position:relative;background:#FFFFFF;border:1px solid #CBCBCB;border-radius:4px;}*/
/*.dropdown-menu a.dropdown-item{display:block;cursor:pointer;padding:0em 1em 0em 1.3em;opacity:0.6;font-size:14px;color:#000000;letter-spacing:0;line-height:27px;}*/
/*.dropdown-menu::after,.dropdown-menu::before{left:10px;border-right:7px solid transparent;border-left:7px solid transparent;position:absolute;display:inline-block;content:''}*/
/*.dropdown-item:focus,.dropdown-menu a.dropdown-item:hover{color:#16181b;text-decoration:none;background-color:#f8f9fa}*/
/*.dropdown-menu a.dropdown-item.active,.dropdown-menu a.dropdown-item:active{color:#fff;text-decoration:none;background-color:#007bff}*/
/*.dropdown-menu::before{top:-8px;border-bottom:7px solid #fff;border-bottom-color:#ccc}*/
/*.dropdown-menu::after{top:-7px;border-bottom:7px solid #fff;border-bottom-color:#fff}*/
/*.dropdown-menu-right{right:0;left:auto}*/

/********************************* Link *******************************/
a{color: #236AEE;text-decoration: none;background-color: transparent;-webkit-text-decoration-skip: objects;}
a:hover{color: #0062cc;text-decoration: none;}

/******************************* Switch Button ************************/
.bp3-control .bp3-control-indicator{display:inline-block;position:relative;margin-top:-3px;margin-right:10px;border:none;box-shadow:inset 0 0 0 1px rgba(16,22,26,.2),inset 0 -1px 0 rgba(16,22,26,.1);background-clip:padding-box;background-color:#ffffff;background-image:linear-gradient(to bottom,rgba(255,255,255,.8),rgba(255,255,255,0));cursor:pointer;width:1em;vertical-align:middle;font-size:16px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.bp3-control.bp3-switch input~.bp3-control-indicator{background:rgba(167,182,194,.5);outline:0}
.bp3-control.bp3-switch .bp3-control-indicator{border:none;border-radius:1.75em;box-shadow:none!important;width:2em;transition:background-color .1s cubic-bezier(.4,1,.75,.9)}
.bp3-control.bp3-switch input:checked~.bp3-control-indicator::before{left:.95em}
.bp3-control.bp3-switch:hover input:checked~.bp3-control-indicator{background:#0069d9}
.bp3-control.bp3-switch input:checked~.bp3-control-indicator{background:#236AEE!important}
.bp3-control.bp3-switch .bp3-control-indicator::before{position:absolute;left:0;margin:2px;border-radius:50%;box-shadow:none!important;background:#fff;width:calc(1em - 4px);height:calc(1em - 4px);-webkit-transition:.3s;transition:.3s}
.bp3-control.bp3-large .bp3-control-indicator{font-size:25px}

/******************************* form Input ************************/
.bp3-input,input{transition:.2s;-webkit-transition:.2s;-moz-transition:.2s;-ms-transition:.2s;-o-transition:.2s}
.bp3-input{outline:0;box-shadow:none;height:38px;padding:0 15px;vertical-align:middle;line-height:21px;color:#383838;font-size:14px;font-weight:400;transition:.2s;-webkit-appearance:none;-moz-appearance:none;background:#FFF;border:1px solid #CBCBCB;border-radius:4px;appearance:none;width:100%}
.bp3-input:hover{background:#FFF;border:1px solid #727272;border-radius:4px;color:#727272}
.bp3-input.bp3-active,.bp3-input:active,.bp3-input:focus{outline:0;background:#FFF;border-color:#6EB1FF;box-shadow:0 0 0 .07rem #6EB1FF}
span.bp3-icon:hover{color:#727272}
.bp3-icon span.bp3-icon:empty:focus,.bp3-input:focus{color:#383838 !important}
.bp3-input-group.bp3-intent-success .bp3-input{border-color:#62A31B;box-shadow:0 0 0 .07rem #62A31B}
.bp3-input-group>.bp3-icon{z-index:1;color:#999}
.bp3-input-group>.bp3-icon:empty{line-height:6px;font-family:Icons16,sans-serif;font-size:16px;font-weight:400;font-style:normal;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased}
.bp3-input-group .bp3-input-action>.bp3-spinner,.bp3-input-group>.bp3-icon{margin:15px}

/******************************* Radio Button ************************/
.bp3-control input[type=radio]:checked~.bp3-control-indicator{box-shadow:none;color:#236AEE;background-color:#fff;border:1px solid #236AEE;transition:.3s}
.bp3-control input[type=checkbox]:checked~.bp3-control-indicator{box-shadow:none;background-color:#236AEE;background-image:none;color:#236AEE;transition:.3s}
.bp3-control input:checked~.bp3-control-indicator{box-shadow:none;color:#236AEE;transition:.3s}
.bp3-control input:not(:disabled):active:checked~.bp3-control-indicator{box-shadow:none;background:#236AEE}
.bp3-control.bp3-radio input:checked~.bp3-control-indicator::before{background:#236AEE;border-radius:100px;width:8px;height:8px;margin:2px auto;border:5px solid #236AEE;transition:.3s}
[type=radio]:focus,[type=checkbox]{display:none}

/************************* Button Increase ********************/
.bp3-button.bp3-decrease,.bp3-button.bp3-increase{background:#F5F5F5;border:1px solid #CBCBCB;border-radius:4px;line-height:15px;padding:.7em 1.1em}
.bp3-button.bp3-decrease span.bp3-icon,.bp3-button.bp3-increase span.bp3-icon{color:#495057;font-size:13px}
.bp3-input-counter-group{width:20%;padding:.5em}
.bp3-input-counter{height:26px;padding:.2em;margin:0}
.bp3-input-counter,.bp3-input-counter:hover{border:none}
.bp3-button.bp3-increase:hover{color:#fff;background:#E3F2FD;border:1px solid #6EB1FF;border-radius:4px}
.bp3-button.bp3-increase span.bp3-icon:hover{color:#6EB1FF}
.bp3-button.bp3-decrease:not([class*=bp3-intent-]).bp3-active,.bp3-button.bp3-decrease:not([class*=bp3-intent-]):active,.bp3-button.bp3-increase:not([class*=bp3-intent-]).bp3-active,.bp3-button.bp3-increase:not([class*=bp3-intent-]):active{box-shadow:inset 0 0 0 1px rgba(125,188,255,.6),inset 0 1px 2px rgba(125,188,255,.6);background-color:#E3F2FD;background-image:none;color:#236AEE}

/************************* Button Decrease ********************/
.bp3-button.bp3-decrease:hover {color: #fff;background: #E3F2FD;border: 1px solid #6EB1FF;border-radius: 4px;}
.bp3-button.bp3-decrease span.bp3-icon:hover{color:#6EB1FF;}

/************************* Button Counter Small ********************/
.bp3-button.bp3-decrease-small,.bp3-button.bp3-increase-small{background:#F5F5F5;border:1px solid #CBCBCB;border-radius:4px;height:10px;padding:.3em .5em}
.bp3-input-counter-small{height:20px;padding:.2em;margin:0}
.bp3-button.bp3-decrease-small span.bp3-icon.bp3-icon-minus,.bp3-button.bp3-increase-small span.bp3-icon.bp3-icon-plus{color:#495057;font-size:13px;line-height:14px}
.bp3-input-group.bp3-input-counter-group{padding:.4em}
.bp3-button.bp3-decrease span.bp3-icon:hover,.bp3-button.bp3-increase span.bp3-icon:hover{color:#6EB1FF}

/******************************* Tabs ************************/
.bd-box{color:#FFF}
.tab-section{width:100%;position:relative}
.bp3-tab-indicator-wrapper .bp3-tab-indicator{height:2px;background-color:#236AEE}
.bp3-tab-indicator-wrapper, .tab-section .bp3-tab.bd-tab{height:48px!important;display:inline-block}

/************* Tabs Button ***********/
#styleButton.tab-section .bp3-tab.bd-tab{padding:12px 35px 8px 35px;font-weight:400;font-size:16px;color:#383838;letter-spacing:0;text-align:center;line-height:28px;}
#styleButton.tab-section .bp3-tab[aria-selected="true"]{border-radius:0;box-shadow:none;transition:0.3s;}
#styleButton.tab-section .bp3-tab-list > *:not(:last-child){margin-right:0px;}
#styleButton.tab-section .bp3-tab.bd-tab[aria-selected=true]:first-child{border-radius:5px 0px 0px 0px;transition:0.3s;-webkit-transition:0.3s;}
#styleButton.tab-section .bp3-tab.bd-tab[aria-selected=true]:last-child{border-radius:0px 5px 0px 0px;transition:0.3s;-webkit-transition:0.3s;}
#styleButton.tab-section .bp3-tab.bd-tab[aria-selected=true]{color:#fff;background-color:#236AEE!important;border-radius:0px 0px 0px 0px;transition:0.3s;-webkit-transition:0.3s;}
#styleButton.tab-section .bp3-tab-list{border:1px solid #E8E8E8;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:8px 8px 0 0}
#styleButton.tab-section .bp3-tab.bd-tab:first-child:hover{color:#fff;background-color: rgba(35, 106, 238, 0.8);border-radius:5px 0px 0px 0px;}
#styleButton.tab-section .bp3-tab.bd-tab:last-child:hover{color:#fff;background-color: rgba(35, 106, 238, 0.8);border-radius:0px 5px 0px 0px;}
#styleButton.tab-section .bp3-tab.bd-tab:hover{color:#fff;background-color: rgba(35, 106, 238, 0.8);}

/************* Tabs Line ***********/
#styleLine.tab-section .bp3-tab.bd-tab{padding:14px 32px 8px 32px;font-size:20px;color:#727272;font-weight:400;line-height:20px;transition:0.3s;-webkit-transition:0.3s;}
#styleLine.tab-section .bp3-tab.bd-tab[aria-selected=true]{background-color:transparent!important;font-size:20px;color:#236AEE;text-align:left;line-height:20px;transition:0.3s;-webkit-transition:0.3s;}
#styleLine.tab-section .bp3-tab[aria-selected="true"]{border-radius:0;box-shadow:inset 0 -2px 0 #236AEE;transition:0.3s;-webkit-transition:0.3s;}
#styleLine.tab-section .bp3-tab.bd-tab:hover{color:#236AEE;box-shadow:inset 0 -2px 0 rgba(35, 106, 238, 0.8);;}
#styleLine.tab-section .bp3-tab-list{border-bottom:1px solid #E8E8E8}
#styleLine.tab-section .bp3-tab-list > *:not(:last-child){margin-right:0px;}

/******************************* Icons ************************/
.bp3-icon{display:inline-block;flex:0 0 auto;vertical-align:text-bottom;color:#CBCBCB}
span.bp3-icon:empty{line-height:1;font-family:Icons20;font-weight:400;font-style:normal;font-size:20px}
.icon-circle{border-radius:50%;background:#FFFFFF;border:1px solid rgba(203,203,203,0.43);padding:6px;color:#727272;}

/******************************* Stars ************************/
span.bp3-icon.bp3-icon-star-empty:hover{color: #1E7BE2;}

/******************************* Range Slider ************************/
.input-range__label{font-family:'Rubik', sans-serif !important;}
.input-range__track{background:#E3F2FD !important;}
.input-range__track--active{background:#236AEE !important;}
.input-range__slider{background:#236AEE !important;border:2px solid #E3F2FD !important;}

/******************************* Tags ************************/
.bp3-tag{display:inline-flex;flex-direction:row;align-items:center;position:relative;border:none;background:#727272;border-radius:4px;box-shadow:none;min-width:20px;max-width:100%;min-height:20px;padding:2px 12px;line-height:16px;color:#f5f8fa;font-size:12px;letter-spacing:0.5px;}
.bp3-tag.bp3-minimal:not([class*=bp3-intent-]){background:#E3F2FD;color:#236AEE}
span.bp3-icon.pb3-icon-small{font-size:10px}
.bp3-link{vertical-align:top}
.bp3-icon-large.bp3-intent-primary,.bp3-icon-standard.bp3-intent-primary,.bp3-icon.bp3-intent-primary{color:#1E7BE2}
.bp3-icon-star-empty{color:#CBCBCB}

/************ Tags Large ************/
.bp3-large .bp3-tag,.bp3-tag.bp3-large{padding:3px 7px;line-height:20px;font-size:14px;color:#236AEE;letter-spacing:.5px;text-align:center;min-width:30px;min-height:20px}

/******************************* Tooltip ************************/
.bp3-tooltip,.bp3-tooltip:before{box-shadow:none !important}
.bp3-tooltip .bp3-popover-arrow:before{box-shadow:none !important}
.bp3-tooltip{box-shadow:none !important}
.bp3-tooltip{-webkit-transform:scale(1);transform:scale(1)}
.bp3-tooltip .bp3-popover-content{background:#394b59;color:#fff;font-size:14px;border:none!important;border-radius:3px;box-shadow:none;padding:7px 18px}
.bp3-tooltip .bp3-popover-arrow-border{fill:#394b59!important;fill-opacity:.1}
.bp3-tooltip .bp3-popover-arrow-fill{fill:#394b59!important}
.bp3-popover{-webkit-transform:scale(1);transform:scale(1);display:inline-block;z-index:20;border-radius:3px;background:#ffffff;}
.bp3-popover .bp3-popover-arrow{margin-top:-0px;}
.bp3-popover .bp3-popover-arrow::before{box-shadow: none !important;}
.bp3-popover{box-shadow: 0 1rem 3rem rgba(0,0,0,0.175) !important;}
Tooltip.tooltip-minimal.bp3-tooltip .bp3-tooltip-content{background: #fff !important;font-size:14px;color:#394b59 !important;text-align:left;border:1px solid #ddd !important;}

/******************************* Tables ************************/
.table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}
.bp3-running-text table,table.bp3-html-table{border-spacing:0;font-size:14px;width:100%}
.bp3-running-text table tbody tr:first-child td,.bp3-running-text table tbody tr:first-child th,table.bp3-html-table tbody tr:first-child td,table.bp3-html-table tbody tr:first-child th{box-shadow:inset 0 1px 0 0 rgba(16,22,26,.15);border-top:1px solid #dee2e6}
.bp3-running-text table td,table.bp3-html-table td{color:#182026;border-top:1px solid #dee2e6}
.bp3-running-text table td,.bp3-running-text table th,table.bp3-html-table td,table.bp3-html-table th{padding:13px;vertical-align:top;text-align:left}

/*************** Tables Dark **********/
.bp3-html-table-dark{color: #fff;background-color: #212529;}
.bp3-running-text table th, table.bp3-html-table th{font-size: 16px;color: #464646 !important;letter-spacing: 0;}
.bp3-running-text table td, table.bp3-html-table td{font-size: 16px;color: #727272;letter-spacing: 0;}
.bp3-running-text table td, table.bp3-html-table-dark td{color:#fff;}

/********** Table Border ********/
.bp3-html-table-dark td,.bp3-html-table-dark th,.bp3-html-table-dark thead th{border:1px solid #32383e;color:#fff!important}
table.bp3-html-table.bp3-html-table-dark tbody tr td:not(:first-child){box-shadow:inset 1px 1px 0 0 rgba(16,22,26,.15);border-bottom:1px solid #32383e}
table.bp3-html-table.bp3-html-table-dark th:not(:first-child){box-shadow:inset 1px 0 0 0 rgba(16,22,26,.15);border-bottom:1px solid #32383e;color:#fff!important}
table.bp3-html-table.bp3-html-table-dark tbody tr td{box-shadow:inset 0 1px 0 0 rgba(16,22,26,.15);border-color:#32383e}
.table-dark td,.table-dark th,.table-dark thead th{border-color:#32383e}

/********** Table head options (Table Dark) ********/
table .thead-dark th{color: #fff !important;background-color: #212529;border-color: #32383e;}

/********** Table head options (Table Light) ********/
table .thead-light th{color: #495057;background-color: #e9ecef;border-color: #dee2e6;}

/********** Table Bordered ********/
.bp3-html-table-bordered{border: 1px solid #ECEEEF;}
table.bp3-html-table.bp3-html-table-bordered tbody tr td{box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);border: 0px solid #ECEEEF;}
table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child){box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15);border: 0px solid #ECEEEF;}

/********** Table Borderless *******/
.table-borderless tbody tr td {border: 0 !important;vertical-align: middle !important;box-shadow: none !important;}

/********** Hoverable Table ********/
.table-hover tbody tr:hover{background-color: rgba(0,0,0,.075);}

/********** Small Table ********/
table.bp3-html-table.bp3-html-table-condensed th, table.bp3-html-table.bp3-html-table-condensed td, table.bp3-html-table.bp3-small th, table.bp3-html-table.bp3-small td{padding-top: 6px;padding-bottom: 6px;font-size: 15px;}

/*********************************** Alerts ***********************************/
.gg-alert{max-width:100%;position:relative;padding:.75rem 1.25rem;margin-bottom:1rem;border:1px solid transparent;border-radius:.25rem}
.gg-alert-success{background:#62A31B;border:1px solid #DDD;border-radius:4px;font-size:16px;color:#3C763D;letter-spacing:0}
.gg-alert-primary{font-size:16px;color:#31708F;background:#6EB1FF;border:1px solid #DDD;border-radius:4px;letter-spacing:0}
.gg-alert-warning{color:#856404;background-color:#fff3cd;border-color:#ffeeba}
.gg-alert-danger{font-size:16px;color:#A94442;background:#FF5252;border:1px solid #DDD;border-radius:4px}

/********** Contextual Table ********/
.table-active,.table-active>td,.table-active>th{background-color:rgba(0,0,0,.075)}
.table-success,.table-success>td,.table-success>th{background-color:#DFF0D8}
.table-primary,.table-primary>td,.table-primary>th{background-color:#D9EDF7}
.table-warning,.table-warning>td,.table-warning>th{background-color:#FCF8E3}
.table- danger,.table-danger>td,.table-danger>th{background-color:#F2DEDE}

/********** Contextual Table ********/
.table-responsive{display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;}

/******************************* Breadcrumb ************************/
.bp3-breadcrumbs,.bp3-breadcrumbs a{display:flex;list-style:none;background-color:#e9ecef}
.bp3-breadcrumbs{flex-wrap:wrap;align-items:center;margin:0;cursor:default;height:auto;padding:0;border-radius:.25rem}
.bp3-breadcrumbs a{-ms-flex-wrap:wrap;flex-wrap:wrap;font-size:16px;padding:.75rem 1rem;color:#383838}
.bp3-breadcrumbs>li::after{margin:0;background:0 0;display:inline-block;color:#6c757d;content:"/";font-weight:500;font-size:22px;height:auto;width:auto}

/******************************* Progress Bar ************************/
.bp3-progress-bar{display:block;position:relative;border-radius:.25rem;background:rgba(92,112,128,.2);width:100%;overflow:hidden;height:1rem}
.bp3-progress-bar .bp3-progress-meter{position:absolute;background:linear-gradient(-45deg,rgba(255,255,255,.2) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.2) 50%,rgba(255,255,255,.2) 75%,transparent 75%);background-color:#007bff;background-size:30px 30px;width:100%;height:100%;transition:width .2s cubic-bezier(.4,1,.75,.9);border-radius:.25rem}
.bp3-progress-bar.bp3-intent-primary .bp3-progress-meter{background-color:#236AEE}
.bp3-progress-bar.bp3-intent-success .bp3-progress-meter{background-color:#28A745}
.bp3-progress-bar.bp3-intent-info .bp3-progress-meter{background-color:#17A2B8}
.bp3-progress-bar.bp3-intent-warning .bp3-progress-meter{background-color:#FFC107}
.bp3-progress-bar.bp3-intent-danger .bp3-progress-meter{background-color:#DC3545}

/*************************** Contextual Text colors *******************/
.bp3-text-primary{color:#007bff!important}
.bp3-text-success{color:#28a745!important}
.bp3-text-danger{color:#dc3545!important}
.bp3-text-warning{color:#ffc107!important}
.bp3-text-info{color:#17a2b8!important}
.bp3-text-white{color:#fff!important}

/************** Background colors*************/
.bp3-bg-primary{background:#236AEE;border-radius:2px}
.bp3-bg-success{background-color:#28a745!important;border-radius:2px}
.bp3-bg-danger{background:#FF5252;border-radius:2px}
.bp3-bg-warning{background:#F5A623;border-radius:2px}
.bp3-bg-info{background:#6EB1FF;border-radius:2px}

/********************************************************** Bootstrap 4 Grid Layouts **********************************************************/
@-ms-viewport{width: device-width;}
html{box-sizing: border-box;-ms-overflow-style: scrollbar;}
*,  *::before, *::after{box-sizing: inherit;}
.container,.container-fluid{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;width:100%}
.flex-column,.flex-row{-webkit-box-direction:normal!important}
.fade{opacity:0;-webkit-transition:opacity .15s linear;-o-transition:opacity .15s linear;transition:opacity .15s linear;}
.fade.in{opacity:1;}
@media (min-width:576px){
    .container{max-width:540px}
}
@media (min-width:768px){
    .container{max-width:720px}
}
@media (min-width:992px){
    .container{max-width:960px}
}
@media (min-width:1200px){
    .container{max-width:1140px}
}
.row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px}
.no-gutters{margin-right:0;margin-left:0}
.no-gutters>.col,.no-gutters>[class*=col-]{padding-right:0;padding-left:0}
.text-shadow{text-shadow:1px 1px 1px #000;}
.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto{position:relative;width:100%;min-height:1px;padding-right:15px;padding-left:15px}
.col{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
.col-1,.col-auto{-webkit-box-flex:0}
.col-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
.col-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
.col-2,.col-3{-webkit-box-flex:0}
.col-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
.col-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
.col-4,.col-5{-webkit-box-flex:0}
.col-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
.col-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
.col-6,.col-7{-webkit-box-flex:0}
.col-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
.col-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
.col-8,.col-9{-webkit-box-flex:0}
.col-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
.col-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
.col-10,.col-11{-webkit-box-flex:0}
.col-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
.col-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
.col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
.order-first{-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1}
.order-last{-webkit-box-ordinal-group:14;-ms-flex-order:13;order:13}
.order-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
.order-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
.order-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
.order-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
.order-4{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4}
.order-5{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5}
.order-6{-webkit-box-ordinal-group:7;-ms-flex-order:6;order:6}
.order-7{-webkit-box-ordinal-group:8;-ms-flex-order:7;order:7}
.order-8{-webkit-box-ordinal-group:9;-ms-flex-order:8;order:8}
.order-9{-webkit-box-ordinal-group:10;-ms-flex-order:9;order:9}
.order-10{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10}
.order-11{-webkit-box-ordinal-group:12;-ms-flex-order:11;order:11}
.order-12{-webkit-box-ordinal-group:13;-ms-flex-order:12;order:12}
.offset-1{margin-left:8.333333%}
.offset-2{margin-left:16.666667%}
.offset-3{margin-left:25%}
.offset-4{margin-left:33.333333%}
.offset-5{margin-left:41.666667%}
.offset-6{margin-left:50%}
.offset-7{margin-left:58.333333%}
.offset-8{margin-left:66.666667%}
.offset-9{margin-left:75%}
.offset-10{margin-left:83.333333%}
.offset-11{margin-left:91.666667%}
.d-none{display:none!important}
.d-inline{display:inline!important}
.d-inline-block{display:inline-block!important}
.d-block{display:block!important}
.d-table{display:table!important}
.d-table-row{display:table-row!important}
.d-table-cell{display:table-cell!important}
.d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important}
.d-inline-flex{display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important}
@media (max-width:576px){
.hidden-xs{display:none !important;}
.visible-xs{display:block !important;}
}
@media (min-width:576px){
    .col-sm{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
    .col-sm-auto{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
    .col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
    .col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
    .col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
    .col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
    .col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
    .col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
    .col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
    .col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
    .col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
    .col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
    .col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
    .col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
    .order-sm-first{-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1}
    .order-sm-last{-webkit-box-ordinal-group:14;-ms-flex-order:13;order:13}
    .order-sm-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
    .order-sm-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
    .order-sm-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
    .order-sm-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
    .order-sm-4{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4}
    .order-sm-5{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5}
    .order-sm-6{-webkit-box-ordinal-group:7;-ms-flex-order:6;order:6}
    .order-sm-7{-webkit-box-ordinal-group:8;-ms-flex-order:7;order:7}
    .order-sm-8{-webkit-box-ordinal-group:9;-ms-flex-order:8;order:8}
    .order-sm-9{-webkit-box-ordinal-group:10;-ms-flex-order:9;order:9}
    .order-sm-10{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10}
    .order-sm-11{-webkit-box-ordinal-group:12;-ms-flex-order:11;order:11}
    .order-sm-12{-webkit-box-ordinal-group:13;-ms-flex-order:12;order:12}
    .offset-sm-0{margin-left:0}
    .offset-sm-1{margin-left:8.333333%}
    .offset-sm-2{margin-left:16.666667%}
    .offset-sm-3{margin-left:25%}
    .offset-sm-4{margin-left:33.333333%}
    .offset-sm-5{margin-left:41.666667%}
    .offset-sm-6{margin-left:50%}
    .offset-sm-7{margin-left:58.333333%}
    .offset-sm-8{margin-left:66.666667%}
    .offset-sm-9{margin-left:75%}
    .offset-sm-10{margin-left:83.333333%}
    .offset-sm-11{margin-left:91.666667%}
    .d-sm-none{display:none!important}
    .d-sm-inline{display:inline!important}
    .d-sm-inline-block{display:inline-block!important}
    .d-sm-block{display:block!important}
.hidden-sm{display:none !important;}
.visible-sm{display:block !important;}
    .d-sm-table{display:table!important}
    .d-sm-table-row{display:table-row!important}
    .d-sm-table-cell{display:table-cell!important}
    .d-sm-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important}
    .d-sm-inline-flex{display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media (min-width:768px){
    .col-md{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
    .col-md-auto{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
    .col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
    .col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
    .col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
    .col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
    .col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
    .col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
    .col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
    .col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
    .col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
    .col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
    .col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
    .col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
    .order-md-first{-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1}
    .order-md-last{-webkit-box-ordinal-group:14;-ms-flex-order:13;order:13}
    .order-md-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
    .order-md-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
    .order-md-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
    .order-md-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
    .order-md-4{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4}
    .order-md-5{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5}
    .order-md-6{-webkit-box-ordinal-group:7;-ms-flex-order:6;order:6}
    .order-md-7{-webkit-box-ordinal-group:8;-ms-flex-order:7;order:7}
    .order-md-8{-webkit-box-ordinal-group:9;-ms-flex-order:8;order:8}
    .order-md-9{-webkit-box-ordinal-group:10;-ms-flex-order:9;order:9}
    .order-md-10{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10}
    .order-md-11{-webkit-box-ordinal-group:12;-ms-flex-order:11;order:11}
    .order-md-12{-webkit-box-ordinal-group:13;-ms-flex-order:12;order:12}
    .offset-md-0{margin-left:0}
    .offset-md-1{margin-left:8.333333%}
    .offset-md-2{margin-left:16.666667%}
    .offset-md-3{margin-left:25%}
    .offset-md-4{margin-left:33.333333%}
    .offset-md-5{margin-left:41.666667%}
    .offset-md-6{margin-left:50%}
    .offset-md-7{margin-left:58.333333%}
    .offset-md-8{margin-left:66.666667%}
    .offset-md-9{margin-left:75%}
    .offset-md-10{margin-left:83.333333%}
    .offset-md-11{margin-left:91.666667%}
    .d-md-none{display:none!important}
    .d-md-inline{display:inline!important}
    .d-md-inline-block{display:inline-block!important}
    .d-md-block{display:block!important}
    .d-md-table{display:table!important}
.hidden-md{display:none !important;}
.visible-md{display:block !important;}
    .d-md-table-row{display:table-row!important}
    .d-md-table-cell{display:table-cell!important}
    .d-md-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important}
    .d-md-inline-flex{display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media (min-width:992px){
    .col-lg{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
    .col-lg-auto{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
    .col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
    .col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
    .col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
    .col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
    .col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
    .col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
    .col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
    .col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
    .col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
    .col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
    .col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
    .col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
    .order-lg-first{-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1}
    .order-lg-last{-webkit-box-ordinal-group:14;-ms-flex-order:13;order:13}
    .order-lg-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
    .order-lg-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
    .order-lg-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
    .order-lg-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
    .order-lg-4{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4}
    .order-lg-5{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5}
    .order-lg-6{-webkit-box-ordinal-group:7;-ms-flex-order:6;order:6}
    .order-lg-7{-webkit-box-ordinal-group:8;-ms-flex-order:7;order:7}
    .order-lg-8{-webkit-box-ordinal-group:9;-ms-flex-order:8;order:8}
    .order-lg-9{-webkit-box-ordinal-group:10;-ms-flex-order:9;order:9}
    .order-lg-10{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10}
    .order-lg-11{-webkit-box-ordinal-group:12;-ms-flex-order:11;order:11}
    .order-lg-12{-webkit-box-ordinal-group:13;-ms-flex-order:12;order:12}
    .offset-lg-0{margin-left:0}
    .offset-lg-1{margin-left:8.333333%}
    .offset-lg-2{margin-left:16.666667%}
    .offset-lg-3{margin-left:25%}
    .offset-lg-4{margin-left:33.333333%}
    .offset-lg-5{margin-left:41.666667%}
    .offset-lg-6{margin-left:50%}
    .offset-lg-7{margin-left:58.333333%}
    .offset-lg-8{margin-left:66.666667%}
    .offset-lg-9{margin-left:75%}
    .offset-lg-10{margin-left:83.333333%}
    .offset-lg-11{margin-left:91.666667%}
    .d-lg-none{display:none!important}
    .d-lg-inline{display:inline!important}
    .d-lg-inline-block{display:inline-block!important}
    .d-lg-block{display:block!important}
.hidden-lg{display:none !important;}
.visible-lg{display:block !important;}
    .d-lg-table{display:table!important}
    .d-lg-table-row{display:table-row!important}
    .d-lg-table-cell{display:table-cell!important}
    .d-lg-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important}
    .d-lg-inline-flex{display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media (min-width:1200px){
    .col-xl{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
    .col-xl-auto{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
    .col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
    .col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
    .col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
    .col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
    .col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
    .col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
    .col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
    .col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
    .col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
    .col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
    .col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
    .col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
    .order-xl-first{-webkit-box-ordinal-group:0;-ms-flex-order:-1;order:-1}
    .order-xl-last{-webkit-box-ordinal-group:14;-ms-flex-order:13;order:13}
    .order-xl-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
    .order-xl-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
    .order-xl-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
    .order-xl-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
    .order-xl-4{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4}
    .order-xl-5{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5}
    .order-xl-6{-webkit-box-ordinal-group:7;-ms-flex-order:6;order:6}
    .order-xl-7{-webkit-box-ordinal-group:8;-ms-flex-order:7;order:7}
    .order-xl-8{-webkit-box-ordinal-group:9;-ms-flex-order:8;order:8}
    .order-xl-9{-webkit-box-ordinal-group:10;-ms-flex-order:9;order:9}
    .order-xl-10{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10}
    .order-xl-11{-webkit-box-ordinal-group:12;-ms-flex-order:11;order:11}
    .order-xl-12{-webkit-box-ordinal-group:13;-ms-flex-order:12;order:12}
    .offset-xl-0{margin-left:0}
    .offset-xl-1{margin-left:8.333333%}
    .offset-xl-2{margin-left:16.666667%}
    .offset-xl-3{margin-left:25%}
    .offset-xl-4{margin-left:33.333333%}
    .offset-xl-5{margin-left:41.666667%}
    .offset-xl-6{margin-left:50%}
    .offset-xl-7{margin-left:58.333333%}
    .offset-xl-8{margin-left:66.666667%}
    .offset-xl-9{margin-left:75%}
    .offset-xl-10{margin-left:83.333333%}
    .offset-xl-11{margin-left:91.666667%}
    .d-xl-none{display:none!important}
    .d-xl-inline{display:inline!important}
    .d-xl-inline-block{display:inline-block!important}
    .d-xl-block{display:block!important}
.hidden-xl{display:none !important;}
.visible-xl{display:block !important;}
    .d-xl-table{display:table!important}
    .d-xl-table-row{display:table-row!important}
    .d-xl-table-cell{display:table-cell!important}
    .d-xl-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important}
    .d-xl-inline-flex{display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media print{.d-print-none{display:none!important}
    .d-print-inline{display:inline!important}
    .d-print-inline-block{display:inline-block!important}
    .d-print-block{display:block!important}
    .d-print-table{display:table!important}
    .d-print-table-row{display:table-row!important}
    .d-print-table-cell{display:table-cell!important}
    .d-print-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important}
    .d-print-inline-flex{display:-webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important}
}
.flex-row{-webkit-box-orient:horizontal!important;-ms-flex-direction:row!important;flex-direction:row!important}
.flex-column{-webkit-box-orient:vertical!important;-ms-flex-direction:column!important;flex-direction:column!important}
.flex-column-reverse,.flex-row-reverse{-webkit-box-direction:reverse!important}
.flex-row-reverse{-webkit-box-orient:horizontal!important;-ms-flex-direction:row-reverse!important;flex-direction:row-reverse!important}
.flex-column-reverse{-webkit-box-orient:vertical!important;-ms-flex-direction:column-reverse!important;flex-direction:column-reverse!important}
.flex-wrap{-ms-flex-wrap:wrap!important;flex-wrap:wrap!important}
.flex-nowrap{-ms-flex-wrap:nowrap!important;flex-wrap:nowrap!important}
.flex-wrap-reverse{-ms-flex-wrap:wrap-reverse!important;flex-wrap:wrap-reverse!important}
.justify-content-start{-webkit-box-pack:start!important;-ms-flex-pack:start!important;justify-content:flex-start!important}
.justify-content-end{-webkit-box-pack:end!important;-ms-flex-pack:end!important;justify-content:flex-end!important}
.justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important}
.justify-content-between{-webkit-box-pack:justify!important;-ms-flex-pack:justify!important;justify-content:space-between!important}
.justify-content-around{-ms-flex-pack:distribute!important;justify-content:space-around!important}
.align-items-start{-webkit-box-align:start!important;-ms-flex-align:start!important;align-items:flex-start!important}
.align-items-end{-webkit-box-align:end!important;-ms-flex-align:end!important;align-items:flex-end!important}
.align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important}
.align-items-baseline{-webkit-box-align:baseline!important;-ms-flex-align:baseline!important;align-items:baseline!important}
.align-items-stretch{-webkit-box-align:stretch!important;-ms-flex-align:stretch!important;align-items:stretch!important}
.align-content-start{-ms-flex-line-pack:start!important;align-content:flex-start!important}
.align-content-end{-ms-flex-line-pack:end!important;align-content:flex-end!important}
.align-content-center{-ms-flex-line-pack:center!important;align-content:center!important}
.align-content-between{-ms-flex-line-pack:justify!important;align-content:space-between!important}
.align-content-around{-ms-flex-line-pack:distribute!important;align-content:space-around!important}
.align-content-stretch{-ms-flex-line-pack:stretch!important;align-content:stretch!important}
.align-self-auto{-ms-flex-item-align:auto!important;align-self:auto!important}
.align-self-start{-ms-flex-item-align:start!important;align-self:flex-start!important}
.align-self-end{-ms-flex-item-align:end!important;align-self:flex-end!important}
.align-self-center{-ms-flex-item-align:center!important;align-self:center!important}
.align-self-baseline{-ms-flex-item-align:baseline!important;align-self:baseline!important}
.align-self-stretch{-ms-flex-item-align:stretch!important;align-self:stretch!important}
@media (min-width:576px){
    .flex-sm-column,.flex-sm-row{-webkit-box-direction:normal!important}
    .flex-sm-row{-webkit-box-orient:horizontal!important;-ms-flex-direction:row!important;flex-direction:row!important}
    .flex-sm-column{-webkit-box-orient:vertical!important;-ms-flex-direction:column!important;flex-direction:column!important}
    .flex-sm-row-reverse{-webkit-box-orient:horizontal!important;-webkit-box-direction:reverse!important;-ms-flex-direction:row-reverse!important;flex-direction:row-reverse!important}
    .flex-sm-column-reverse{-webkit-box-orient:vertical!important;-webkit-box-direction:reverse!important;-ms-flex-direction:column-reverse!important;flex-direction:column-reverse!important}
    .flex-sm-wrap{-ms-flex-wrap:wrap!important;flex-wrap:wrap!important}
    .flex-sm-nowrap{-ms-flex-wrap:nowrap!important;flex-wrap:nowrap!important}
    .flex-sm-wrap-reverse{-ms-flex-wrap:wrap-reverse!important;flex-wrap:wrap-reverse!important}
    .justify-content-sm-start{-webkit-box-pack:start!important;-ms-flex-pack:start!important;justify-content:flex-start!important}
    .justify-content-sm-end{-webkit-box-pack:end!important;-ms-flex-pack:end!important;justify-content:flex-end!important}
    .justify-content-sm-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important}
    .justify-content-sm-between{-webkit-box-pack:justify!important;-ms-flex-pack:justify!important;justify-content:space-between!important}
    .justify-content-sm-around{-ms-flex-pack:distribute!important;justify-content:space-around!important}
    .align-items-sm-start{-webkit-box-align:start!important;-ms-flex-align:start!important;align-items:flex-start!important}
    .align-items-sm-end{-webkit-box-align:end!important;-ms-flex-align:end!important;align-items:flex-end!important}
    .align-items-sm-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important}
    .align-items-sm-baseline{-webkit-box-align:baseline!important;-ms-flex-align:baseline!important;align-items:baseline!important}
    .align-items-sm-stretch{-webkit-box-align:stretch!important;-ms-flex-align:stretch!important;align-items:stretch!important}
    .align-content-sm-start{-ms-flex-line-pack:start!important;align-content:flex-start!important}
    .align-content-sm-end{-ms-flex-line-pack:end!important;align-content:flex-end!important}
    .align-content-sm-center{-ms-flex-line-pack:center!important;align-content:center!important}
    .align-content-sm-between{-ms-flex-line-pack:justify!important;align-content:space-between!important}
    .align-content-sm-around{-ms-flex-line-pack:distribute!important;align-content:space-around!important}
    .align-content-sm-stretch{-ms-flex-line-pack:stretch!important;align-content:stretch!important}
    .align-self-sm-auto{-ms-flex-item-align:auto!important;align-self:auto!important}
    .align-self-sm-start{-ms-flex-item-align:start!important;align-self:flex-start!important}
    .align-self-sm-end{-ms-flex-item-align:end!important;align-self:flex-end!important}
    .align-self-sm-center{-ms-flex-item-align:center!important;align-self:center!important}
    .align-self-sm-baseline{-ms-flex-item-align:baseline!important;align-self:baseline!important}
    .align-self-sm-stretch{-ms-flex-item-align:stretch!important;align-self:stretch!important}
}
@media (min-width:768px){
    .flex-md-column,.flex-md-row{-webkit-box-direction:normal!important}
    .flex-md-row{-webkit-box-orient:horizontal!important;-ms-flex-direction:row!important;flex-direction:row!important}
    .flex-md-column{-webkit-box-orient:vertical!important;-ms-flex-direction:column!important;flex-direction:column!important}
    .flex-md-row-reverse{-webkit-box-orient:horizontal!important;-webkit-box-direction:reverse!important;-ms-flex-direction:row-reverse!important;flex-direction:row-reverse!important}
    .flex-md-column-reverse{-webkit-box-orient:vertical!important;-webkit-box-direction:reverse!important;-ms-flex-direction:column-reverse!important;flex-direction:column-reverse!important}
    .flex-md-wrap{-ms-flex-wrap:wrap!important;flex-wrap:wrap!important}
    .flex-md-nowrap{-ms-flex-wrap:nowrap!important;flex-wrap:nowrap!important}
    .flex-md-wrap-reverse{-ms-flex-wrap:wrap-reverse!important;flex-wrap:wrap-reverse!important}
    .justify-content-md-start{-webkit-box-pack:start!important;-ms-flex-pack:start!important;justify-content:flex-start!important}
    .justify-content-md-end{-webkit-box-pack:end!important;-ms-flex-pack:end!important;justify-content:flex-end!important}
    .justify-content-md-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important}
    .justify-content-md-between{-webkit-box-pack:justify!important;-ms-flex-pack:justify!important;justify-content:space-between!important}
    .justify-content-md-around{-ms-flex-pack:distribute!important;justify-content:space-around!important}
    .align-items-md-start{-webkit-box-align:start!important;-ms-flex-align:start!important;align-items:flex-start!important}
    .align-items-md-end{-webkit-box-align:end!important;-ms-flex-align:end!important;align-items:flex-end!important}
    .align-items-md-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important}
    .align-items-md-baseline{-webkit-box-align:baseline!important;-ms-flex-align:baseline!important;align-items:baseline!important}
    .align-items-md-stretch{-webkit-box-align:stretch!important;-ms-flex-align:stretch!important;align-items:stretch!important}
    .align-content-md-start{-ms-flex-line-pack:start!important;align-content:flex-start!important}
    .align-content-md-end{-ms-flex-line-pack:end!important;align-content:flex-end!important}
    .align-content-md-center{-ms-flex-line-pack:center!important;align-content:center!important}
    .align-content-md-between{-ms-flex-line-pack:justify!important;align-content:space-between!important}
    .align-content-md-around{-ms-flex-line-pack:distribute!important;align-content:space-around!important}
    .align-content-md-stretch{-ms-flex-line-pack:stretch!important;align-content:stretch!important}
    .align-self-md-auto{-ms-flex-item-align:auto!important;align-self:auto!important}
    .align-self-md-start{-ms-flex-item-align:start!important;align-self:flex-start!important}
    .align-self-md-end{-ms-flex-item-align:end!important;align-self:flex-end!important}
    .align-self-md-center{-ms-flex-item-align:center!important;align-self:center!important}
    .align-self-md-baseline{-ms-flex-item-align:baseline!important;align-self:baseline!important}
    .align-self-md-stretch{-ms-flex-item-align:stretch!important;align-self:stretch!important}
}
@media (min-width:992px){
    .flex-lg-column,.flex-lg-row{-webkit-box-direction:normal!important}
    .flex-lg-row{-webkit-box-orient:horizontal!important;-ms-flex-direction:row!important;flex-direction:row!important}
    .flex-lg-column{-webkit-box-orient:vertical!important;-ms-flex-direction:column!important;flex-direction:column!important}
    .flex-lg-row-reverse{-webkit-box-orient:horizontal!important;-webkit-box-direction:reverse!important;-ms-flex-direction:row-reverse!important;flex-direction:row-reverse!important}
    .flex-lg-column-reverse{-webkit-box-orient:vertical!important;-webkit-box-direction:reverse!important;-ms-flex-direction:column-reverse!important;flex-direction:column-reverse!important}
    .flex-lg-wrap{-ms-flex-wrap:wrap!important;flex-wrap:wrap!important}
    .flex-lg-nowrap{-ms-flex-wrap:nowrap!important;flex-wrap:nowrap!important}
    .flex-lg-wrap-reverse{-ms-flex-wrap:wrap-reverse!important;flex-wrap:wrap-reverse!important}
    .justify-content-lg-start{-webkit-box-pack:start!important;-ms-flex-pack:start!important;justify-content:flex-start!important}
    .justify-content-lg-end{-webkit-box-pack:end!important;-ms-flex-pack:end!important;justify-content:flex-end!important}
    .justify-content-lg-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important}
    .justify-content-lg-between{-webkit-box-pack:justify!important;-ms-flex-pack:justify!important;justify-content:space-between!important}
    .justify-content-lg-around{-ms-flex-pack:distribute!important;justify-content:space-around!important}
    .align-items-lg-start{-webkit-box-align:start!important;-ms-flex-align:start!important;align-items:flex-start!important}
    .align-items-lg-end{-webkit-box-align:end!important;-ms-flex-align:end!important;align-items:flex-end!important}
    .align-items-lg-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important}
    .align-items-lg-baseline{-webkit-box-align:baseline!important;-ms-flex-align:baseline!important;align-items:baseline!important}
    .align-items-lg-stretch{-webkit-box-align:stretch!important;-ms-flex-align:stretch!important;align-items:stretch!important}
    .align-content-lg-start{-ms-flex-line-pack:start!important;align-content:flex-start!important}
    .align-content-lg-end{-ms-flex-line-pack:end!important;align-content:flex-end!important}
    .align-content-lg-center{-ms-flex-line-pack:center!important;align-content:center!important}
    .align-content-lg-between{-ms-flex-line-pack:justify!important;align-content:space-between!important}
    .align-content-lg-around{-ms-flex-line-pack:distribute!important;align-content:space-around!important}
    .align-content-lg-stretch{-ms-flex-line-pack:stretch!important;align-content:stretch!important}
    .align-self-lg-auto{-ms-flex-item-align:auto!important;align-self:auto!important}
    .align-self-lg-start{-ms-flex-item-align:start!important;align-self:flex-start!important}
    .align-self-lg-end{-ms-flex-item-align:end!important;align-self:flex-end!important}
    .align-self-lg-center{-ms-flex-item-align:center!important;align-self:center!important}
    .align-self-lg-baseline{-ms-flex-item-align:baseline!important;align-self:baseline!important}
    .align-self-lg-stretch{-ms-flex-item-align:stretch!important;align-self:stretch!important}
}
@media (min-width:1200px){
    .flex-xl-column,.flex-xl-row{-webkit-box-direction:normal!important}
    .flex-xl-row{-webkit-box-orient:horizontal!important;-ms-flex-direction:row!important;flex-direction:row!important}
    .flex-xl-column{-webkit-box-orient:vertical!important;-ms-flex-direction:column!important;flex-direction:column!important}
    .flex-xl-row-reverse{-webkit-box-orient:horizontal!important;-webkit-box-direction:reverse!important;-ms-flex-direction:row-reverse!important;flex-direction:row-reverse!important}
    .flex-xl-column-reverse{-webkit-box-orient:vertical!important;-webkit-box-direction:reverse!important;-ms-flex-direction:column-reverse!important;flex-direction:column-reverse!important}
    .flex-xl-wrap{-ms-flex-wrap:wrap!important;flex-wrap:wrap!important}
    .flex-xl-nowrap{-ms-flex-wrap:nowrap!important;flex-wrap:nowrap!important}
    .flex-xl-wrap-reverse{-ms-flex-wrap:wrap-reverse!important;flex-wrap:wrap-reverse!important}
    .justify-content-xl-start{-webkit-box-pack:start!important;-ms-flex-pack:start!important;justify-content:flex-start!important}
    .justify-content-xl-end{-webkit-box-pack:end!important;-ms-flex-pack:end!important;justify-content:flex-end!important}
    .justify-content-xl-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important}
    .justify-content-xl-between{-webkit-box-pack:justify!important;-ms-flex-pack:justify!important;justify-content:space-between!important}
    .justify-content-xl-around{-ms-flex-pack:distribute!important;justify-content:space-around!important}
    .align-items-xl-start{-webkit-box-align:start!important;-ms-flex-align:start!important;align-items:flex-start!important}
    .align-items-xl-end{-webkit-box-align:end!important;-ms-flex-align:end!important;align-items:flex-end!important}
    .align-items-xl-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important}
    .align-items-xl-baseline{-webkit-box-align:baseline!important;-ms-flex-align:baseline!important;align-items:baseline!important}
    .align-items-xl-stretch{-webkit-box-align:stretch!important;-ms-flex-align:stretch!important;align-items:stretch!important}
    .align-content-xl-start{-ms-flex-line-pack:start!important;align-content:flex-start!important}
    .align-content-xl-end{-ms-flex-line-pack:end!important;align-content:flex-end!important}
    .align-content-xl-center{-ms-flex-line-pack:center!important;align-content:center!important}
    .align-content-xl-between{-ms-flex-line-pack:justify!important;align-content:space-between!important}
    .align-content-xl-around{-ms-flex-line-pack:distribute!important;align-content:space-around!important}
    .align-content-xl-stretch{-ms-flex-line-pack:stretch!important;align-content:stretch!important}
    .align-self-xl-auto{-ms-flex-item-align:auto!important;align-self:auto!important}
    .align-self-xl-start{-ms-flex-item-align:start!important;align-self:flex-start!important}
    .align-self-xl-end{-ms-flex-item-align:end!important;align-self:flex-end!important}
    .align-self-xl-center{-ms-flex-item-align:center!important;align-self:center!important}
    .align-self-xl-baseline{-ms-flex-item-align:baseline!important;align-self:baseline!important}
    .align-self-xl-stretch{-ms-flex-item-align:stretch!important;align-self:stretch!important}
}

/**************************** Modal ***********************/
.bp3-button.bp3-minimal:hover{-webkit-box-shadow:none;box-shadow:none;background:rgba(167,182,194,.3);text-decoration:none;color:#182026;border:none}
.bp3-button.bp3-minimal{-webkit-box-shadow:none;box-shadow:none;background:0 0;padding:4px 15px;border:none;}
.bp3-dialog-header{display:flex;flex:0 0 auto;align-items:center;border-radius:2px 2px 0 0;box-shadow:none;background:#fff;min-height:40px;padding:1.3em 1em 1.3em 4em}
.bp3-dialog-header .bp3-heading{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;word-wrap:normal;flex:1 1 auto;margin:0;line-height:inherit;font-size:18px}
.bp3-dialog-container{min-height:38%;}
.bp3-dialog{width:500px;border-radius:2px;background:#FFF;box-shadow:0 0 16px 0 rgba(210,210,210,.5)}
.bp3-dialog-body h4{font-size:18px}
.bp3-dialog-body{flex:1 1 auto;margin:2em 4em;line-height:18px;background:#fff}
.bp3-dialog-body p{font-size:14px}
.bp3-dialog-footer-actions .bp3-button{margin-left:10px;padding:5px 15px;border-radius:3px!important;font-size:14px}
.bp3-dialog-footer-actions .bp3-button:hover{color:#fff!important}
.bp3-overlay-backdrop{background:rgba(227,242,253,.8)}

/********************** Modal Small ******************/
.bp3-dialog-small{width: 300px !important;}

/********************** Modal Large ******************/
.bp3-dialog-large{width: 800px;}

/********************************************************** Bootstrap 4 Utilities **********************************************************/
.container,.container-fluid{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}
.display-1,.display-2,.display-3,.display-4{font-weight:300;line-height:1.2}
.blockquote,hr{margin-bottom:1rem}
.container-fluid{max-width:none}
.container{max-width:1140px;width:100%}
.img-fluid,.img-thumbnail{max-width:100%;height:auto}
.display-1{font-size:6rem}
.display-2{font-size:5.5rem}
.display-3{font-size:4.5rem}
.display-4{font-size:3.5rem}
.bold{font-weight:600;}
.font-10{font-size:10px !important;}
.font-11{font-size:11px !important;}
.font-12{font-size:12px !important;}
.font-13{font-size:13px !important;}
.font-14{font-size:14px !important;}
.font-15{font-size:15px !important;}
.font-16{font-size:16px !important;}
.font-17{font-size:17px !important;}
.font-18{font-size:18px !important;}
.font-19{font-size:19px !important;}
.font-20{font-size:20px !important;}
hr{margin-top:1rem;border:0;border-top:1px solid rgba(0,0,0,.1)}
.small,small{font-size:80%;font-weight:400}
.mark,mark{padding:.2em;background-color:#fcf8e3}
.list-inline,.list-unstyled{padding-left:0;list-style:none}
.list-inline-item{display:inline-block}
.list-inline-item:not(:last-child){margin-right:.5rem}
.initialism{font-size:90%;text-transform:uppercase}
.blockquote{font-size:1.25rem}
.blockquote-footer{display:block;font-size:80%;color:#6c757d}
.blockquote-footer::before{content:"\2014 \00A0"}
.img-thumbnail{padding:.25rem;background-color:#fff;border:1px solid #dee2e6;border-radius:.25rem}
.figure{display:inline-block}
.figure-img{margin-bottom:.5rem;line-height:1}
.figure-caption{font-size:90%;color:#6c757d}
code,kbd{font-size:87.5%}
a>code,pre code{color:inherit}
code{color:#e83e8c;word-break:break-word}
kbd{padding:.2rem .4rem;color:#fff;background-color:#212529;border-radius:.2rem}
kbd kbd{padding:0;font-size:100%;font-weight:700}
pre{display:block;font-size:87.5%;color:#212529}
pre code{font-size:inherit;word-break:normal}
.pre-scrollable{max-height:340px;overflow-y:scroll}

/**************************** Border ***********************/
.border{border:1px solid #dee2e6!important}
.border-top{border-top:1px solid #dee2e6!important}
.border-right{border-right:1px solid #dee2e6!important}
.border-bottom{border-bottom:1px solid #dee2e6!important}
.border-left{border-left:1px solid #dee2e6!important}
.border-0{border:0!important}
.border-top-0{border-top:0!important}
.border-right-0{border-right:0!important}
.border-bottom-0{border-bottom:0!important}
.border-left-0{border-left:0!important}

/***************** Border Colors *************/
.border-primary{border-color:#007bff!important}
.border-secondary{border-color:#6c757d!important}
.border-success{border-color:#28a745!important}
.border-danger{border-color:#dc3545!important}
.border-warning{border-color:#ffc107!important}
.border-info{border-color:#17a2b8!important}
.border-light{border-color:#f8f9fa!important}
.border-dark{border-color:#343a40!important}
.border-white{border-color:#fff!important}

/***************** Border Radius *************/
.rounded-right,.rounded-top{border-top-right-radius:.25rem!important}
.rounded-bottom,.rounded-right{border-bottom-right-radius:.25rem!important}
.rounded-left,.rounded-top{border-top-left-radius:.25rem!important}
.rounded-bottom,.rounded-left{border-bottom-left-radius:.25rem!important}
.rounded{border-radius:.25rem!important}
.rounded-circle{border-radius:50%!important}
.rounded-0{border-radius:0!important}

/************************************** Clearfix *************************************/
.clearfix::after{display:block;content:"";clear:both;}

/************************************** Close *************************************/
.close:not(:disabled):not(.disabled){cursor:pointer}
.close{float:right;font-size:1.5rem;font-weight:700;line-height:1;color:#000;text-shadow:0 1px 0 #fff;opacity:.5}
.close:hover{opacity:.8}
button.close{padding:0;background-color:transparent;border:0;-webkit-appearance:none}

/************************************** Display *************************************/
/******************* Display None ****************/
.d-none{display:none!important;}

/****************** Display Inline ***************/
.d-inline{display:inline!important;}

/*************** Display Inline Block *************/
.d-inline-block{display:inline-block!important;}

/******************* Display Block ****************/
.d-block{display:block!important;}

/******************* Display Table ****************/
.d-table{display:table!important;}

/***************** Display Table-Cell *************/
.d-table-cell{display:table-cell!important;}

/****************** Display Table Row**************/
.d-table-row{display:table-row!important;}

/******************* Display Flex *****************/
.d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}

/******************* Display Inline Flex****************/
.d-inline-flex {display: -webkit-inline-box!important;display:-ms-inline-flexbox!important;display:inline-flex!important;}

/************************************** Embeds *************************************/
.embed-responsive{position:relative;display:block;width:100%;padding:0;overflow:hidden}
.embed-responsive::before{display:block;content:""}
.embed-responsive .embed-responsive-item,.embed-responsive embed,.embed-responsive iframe,.embed-responsive object,.embed-responsive video{position:absolute;top:0;bottom:0;left:0;width:100%;height:100%;border:0}
.embed-responsive-21by9::before{padding-top:42.857143%}
.embed-responsive-16by9::before{padding-top:56.25%}
.embed-responsive-4by3::before{padding-top:75%}
.embed-responsive-1by1::before{padding-top:100%}

/************************************** Float *************************************/
.float-left{float:left!important}
.float-right{float:right!important}
.float-none{float:none!important}
@media (min-width: 576px){
    .float-sm-left{float:left!important}
    .float-sm-right{float:right!important}
    .float-sm-none{float:none!important}
}
@media (min-width: 768px){
    .float-md-left{float:left!important}
    .float-md-right{float:right!important}
    .float-md-none{float:none!important}
}
@media (min-width: 992px){
    .float-lg-left{float:left!important}
    .float-lg-right{float:right!important}
    .float-lg-none{float:none!important}
}
@media (min-width: 1200px){
    .float-xl-left{float:left!important}
    .float-xl-right{float:right!important}
    .float-xl-none{float:none!important}
}

/************************************** Image Replacement *************************************/
.text-hide{font:0/0 a;color:transparent;text-shadow:none;background-color:transparent;border:0;}

/************************************** Image Replacement *************************************/
.position-static{position:static!important}
.position-relative{position:relative!important}
.position-absolute{position:absolute!important}
.position-fixed{position:fixed!important}
.position-sticky{position:-webkit-sticky!important;position:sticky!important}
.fixed-bottom,.fixed-top{position:fixed;right:0;left:0;z-index:1030}
.fixed-top{top:0}
.fixed-bottom{bottom:0}
@supports ((position:-webkit-sticky) or (position:sticky)){.sticky-top{position:-webkit-sticky;position:sticky;top:0;z-index:1020}
}

/************************************** Screenreaders *************************************/
.sr-only{position:absolute;width:1px;height:1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;border:0}
.sr-only-focusable:active,.sr-only-focusable:focus{position:static;width:auto;height:auto;overflow:visible;clip:auto;white-space:normal}

/************************************** Sizing *************************************/
.w-25{width:25%!important}
.w-50{width:50%!important}
.w-75{width:75%!important}
.w-100{width:100%!important}
.h-25{height:25%!important}
.h-50{height:50%!important}
.h-75{height:75%!important}
.h-100{height:100%!important}
.mw-100{max-width:100%!important}
.mh-100{max-height:100%!important}

/************************************** Sizing *************************************/
.m-0{margin:0!important}
.mt-0,.my-0{margin-top:0!important}
.mr-0,.mx-0{margin-right:0!important}
.mb-0,.my-0{margin-bottom:0!important}
.ml-0,.mx-0{margin-left:0!important}
.m-1{margin:.25rem!important}
.mt-1,.my-1{margin-top:.25rem!important}
.mr-1,.mx-1{margin-right:.25rem!important}
.mb-1,.my-1{margin-bottom:.25rem!important}
.ml-1,.mx-1{margin-left:.25rem!important}
.m-2{margin:.5rem!important}
.mt-2,.my-2{margin-top:.5rem!important}
.mr-2,.mx-2{margin-right:.5rem!important}
.mb-2,.my-2{margin-bottom:.5rem!important}
.ml-2,.mx-2{margin-left:.5rem!important}
.m-3{margin:1rem!important}
.mt-3,.my-3{margin-top:1rem!important}
.mr-3,.mx-3{margin-right:1rem!important}
.mb-3,.my-3{margin-bottom:1rem!important}
.ml-3,.mx-3{margin-left:1rem!important}
.m-4{margin:1.5rem!important}
.mt-4,.my-4{margin-top:1.5rem!important}
.mr-4,.mx-4{margin-right:1.5rem!important}
.mb-4,.my-4{margin-bottom:1.5rem!important}
.ml-4,.mx-4{margin-left:1.5rem!important}
.m-5{margin:3rem!important}
.mt-5,.my-5{margin-top:3rem!important}
.mr-5,.mx-5{margin-right:3rem!important}
.mb-5,.my-5{margin-bottom:3rem!important}
.ml-5,.mx-5{margin-left:3rem!important}
.p-0{padding:0!important}
.pt-0,.py-0{padding-top:0!important}
.pr-0,.px-0{padding-right:0!important}
.pb-0,.py-0{padding-bottom:0!important}
.pl-0,.px-0{padding-left:0!important}
.p-1{padding:.25rem!important}
.pt-1,.py-1{padding-top:.25rem!important}
.pr-1,.px-1{padding-right:.25rem!important}
.pb-1,.py-1{padding-bottom:.25rem!important}
.pl-1,.px-1{padding-left:.25rem!important}
.p-2{padding:.5rem!important}
.pt-2,.py-2{padding-top:.5rem!important}
.pr-2,.px-2{padding-right:.5rem!important}
.pb-2,.py-2{padding-bottom:.5rem!important}
.pl-2,.px-2{padding-left:.5rem!important}
.p-3{padding:1rem!important}
.pt-3,.py-3{padding-top:1rem!important}
.pr-3,.px-3{padding-right:1rem!important}
.pb-3,.py-3{padding-bottom:1rem!important}
.pl-3,.px-3{padding-left:1rem!important}
.p-4{padding:1.5rem!important}
.pt-4,.py-4{padding-top:1.5rem!important}
.pr-4,.px-4{padding-right:1.5rem!important}
.pb-4,.py-4{padding-bottom:1.5rem!important}
.pl-4,.px-4{padding-left:1.5rem!important}
.p-5{padding:3rem!important}
.pt-5,.py-5{padding-top:3rem!important}
.pr-5,.px-5{padding-right:3rem!important}
.pb-5,.py-5{padding-bottom:3rem!important}
.pl-5,.px-5{padding-left:3rem!important}
.m-auto{margin:auto!important}
.mt-auto,.my-auto{margin-top:auto!important}
.mr-auto,.mx-auto{margin-right:auto!important}
.mb-auto,.my-auto{margin-bottom:auto!important}
.ml-auto,.mx-auto{margin-left:auto!important}

@media (max-width:576px){
.text-center-xs{text-align:center !important;}
.m-s-0{margin:0!important}
    .mt-s-0,.my-s-0{margin-top:0!important}
    .mr-s-0,.mx-s-0{margin-right:0!important}
    .mb-s-0,.my-s-0{margin-bottom:0!important}
    .ml-s-0,.mx-s-0{margin-left:0!important}
    .m-s-1{margin:.25rem!important}
    .mt-s-1,.my-s-1{margin-top:.25rem!important}
    .mr-s-1,.mx-s-1{margin-right:.25rem!important}
    .mb-s-1,.my-s-1{margin-bottom:.25rem!important}
    .ml-s-1,.mx-s-1{margin-left:.25rem!important}
    .m-s-2{margin:.5rem!important}
    .mt-s-2,.my-s-2{margin-top:.5rem!important}
    .mr-s-2,.mx-s-2{margin-right:.5rem!important}
    .mb-s-2,.my-s-2{margin-bottom:.5rem!important}
    .ml-s-2,.mx-s-2{margin-left:.5rem!important}
    .m-s-3{margin:1rem!important}
    .mt-s-3,.my-s-3{margin-top:1rem!important}
    .mr-s-3,.mx-s-3{margin-right:1rem!important}
    .mb-s-3,.my-s-3{margin-bottom:1rem!important}
    .ml-s-3,.mx-s-3{margin-left:1rem!important}
    .m-s-4{margin:1.5rem!important}
    .mt-s-4,.my-s-4{margin-top:1.5rem!important}
    .mr-s-4,.mx-s-4{margin-right:1.5rem!important}
    .mb-s-4,.my-s-4{margin-bottom:1.5rem!important}
    .ml-s-4,.mx-s-4{margin-left:1.5rem!important}
    .m-s-5{margin:3rem!important}
    .mt-s-5,.my-s-5{margin-top:3rem!important}
    .mr-s-5,.mx-s-5{margin-right:3rem!important}
    .mb-s-5,.my-s-5{margin-bottom:3rem!important}
    .ml-s-5,.mx-s-5{margin-left:3rem!important}
    .p-s-0{padding:0!important}
    .pt-s-0,.py-s-0{padding-top:0!important}
    .pr-s-0,.px-s-0{padding-right:0!important}
    .pb-s-0,.py-s-0{padding-bottom:0!important}
    .pl-s-0,.px-s-0{padding-left:0!important}
    .p-s-1{padding:.25rem!important}
    .pt-s-1,.py-s-1{padding-top:.25rem!important}
    .pr-s-1,.px-s-1{padding-right:.25rem!important}
    .pb-s-1,.py-s-1{padding-bottom:.25rem!important}
    .pl-s-1,.px-s-1{padding-left:.25rem!important}
    .p-s-2{padding:.5rem!important}
    .pt-s-2,.py-s-2{padding-top:.5rem!important}
    .pr-s-2,.px-s-2{padding-right:.5rem!important}
    .pb-s-2,.py-s-2{padding-bottom:.5rem!important}
    .pl-s-2,.px-s-2{padding-left:.5rem!important}
    .p-s-3{padding:1rem!important}
    .pt-s-3,.py-s-3{padding-top:1rem!important}
    .pr-s-3,.px-s-3{padding-right:1rem!important}
    .pb-s-3,.py-s-3{padding-bottom:1rem!important}
    .pl-s-3,.px-s-3{padding-left:1rem!important}
    .p-s-4{padding:1.5rem!important}
    .pt-s-4,.py-s-4{padding-top:1.5rem!important}
    .pr-s-4,.px-s-4{padding-right:1.5rem!important}
    .pb-s-4,.py-s-4{padding-bottom:1.5rem!important}
    .pl-s-4,.px-s-4{padding-left:1.5rem!important}
    .p-s-5{padding:3rem!important}
    .pt-s-5,.py-s-5{padding-top:3rem!important}
    .pr-s-5,.px-s-5{padding-right:3rem!important}
    .pb-s-5,.py-s-5{padding-bottom:3rem!important}
    .pl-s-5,.px-s-5{padding-left:3rem!important}
    .m-s-auto{margin:auto!important}
    .mt-s-auto,.my-s-auto{margin-top:auto!important}
    .mr-s-auto,.mx-s-auto{margin-right:auto!important}
    .mb-s-auto,.my-s-auto{margin-bottom:auto!important}
    .ml-s-auto,.mx-s-auto{margin-left:auto!important}
}

@media (min-width:577px) and (max-width:768px){
.text-center-xs{text-align:center !important;}
.m-xs-0{margin:0!important}
    .mt-xs-0,.my-xs-0{margin-top:0!important}
    .mr-xs-0,.mx-xs-0{margin-right:0!important}
    .mb-xs-0,.my-xs-0{margin-bottom:0!important}
    .ml-xs-0,.mx-xs-0{margin-left:0!important}
    .m-xs-1{margin:.25rem!important}
    .mt-xs-1,.my-xs-1{margin-top:.25rem!important}
    .mr-xs-1,.mx-xs-1{margin-right:.25rem!important}
    .mb-xs-1,.my-xs-1{margin-bottom:.25rem!important}
    .ml-xs-1,.mx-xs-1{margin-left:.25rem!important}
    .m-xs-2{margin:.5rem!important}
    .mt-xs-2,.my-xs-2{margin-top:.5rem!important}
    .mr-xs-2,.mx-xs-2{margin-right:.5rem!important}
    .mb-xs-2,.my-xs-2{margin-bottom:.5rem!important}
    .ml-xs-2,.mx-xs-2{margin-left:.5rem!important}
    .m-xs-3{margin:1rem!important}
    .mt-xs-3,.my-xs-3{margin-top:1rem!important}
    .mr-xs-3,.mx-xs-3{margin-right:1rem!important}
    .mb-xs-3,.my-xs-3{margin-bottom:1rem!important}
    .ml-xs-3,.mx-xs-3{margin-left:1rem!important}
    .m-xs-4{margin:1.5rem!important}
    .mt-xs-4,.my-xs-4{margin-top:1.5rem!important}
    .mr-xs-4,.mx-xs-4{margin-right:1.5rem!important}
    .mb-xs-4,.my-xs-4{margin-bottom:1.5rem!important}
    .ml-xs-4,.mx-xs-4{margin-left:1.5rem!important}
    .m-xs-5{margin:3rem!important}
    .mt-xs-5,.my-xs-5{margin-top:3rem!important}
    .mr-xs-5,.mx-xs-5{margin-right:3rem!important}
    .mb-xs-5,.my-xs-5{margin-bottom:3rem!important}
    .ml-xs-5,.mx-xs-5{margin-left:3rem!important}
    .p-xs-0{padding:0!important}
    .pt-xs-0,.py-xs-0{padding-top:0!important}
    .pr-xs-0,.px-xs-0{padding-right:0!important}
    .pb-xs-0,.py-xs-0{padding-bottom:0!important}
    .pl-xs-0,.px-xs-0{padding-left:0!important}
    .p-xs-1{padding:.25rem!important}
    .pt-xs-1,.py-xs-1{padding-top:.25rem!important}
    .pr-xs-1,.px-xs-1{padding-right:.25rem!important}
    .pb-xs-1,.py-xs-1{padding-bottom:.25rem!important}
    .pl-xs-1,.px-xs-1{padding-left:.25rem!important}
    .p-xs-2{padding:.5rem!important}
    .pt-xs-2,.py-xs-2{padding-top:.5rem!important}
    .pr-xs-2,.px-xs-2{padding-right:.5rem!important}
    .pb-xs-2,.py-xs-2{padding-bottom:.5rem!important}
    .pl-xs-2,.px-xs-2{padding-left:.5rem!important}
    .p-xs-3{padding:1rem!important}
    .pt-xs-3,.py-xs-3{padding-top:1rem!important}
    .pr-xs-3,.px-xs-3{padding-right:1rem!important}
    .pb-xs-3,.py-xs-3{padding-bottom:1rem!important}
    .pl-xs-3,.px-xs-3{padding-left:1rem!important}
    .p-xs-4{padding:1.5rem!important}
    .pt-xs-4,.py-xs-4{padding-top:1.5rem!important}
    .pr-xs-4,.px-xs-4{padding-right:1.5rem!important}
    .pb-xs-4,.py-xs-4{padding-bottom:1.5rem!important}
    .pl-xs-4,.px-xs-4{padding-left:1.5rem!important}
    .p-xs-5{padding:3rem!important}
    .pt-xs-5,.py-xs-5{padding-top:3rem!important}
    .pr-xs-5,.px-xs-5{padding-right:3rem!important}
    .pb-xs-5,.py-xs-5{padding-bottom:3rem!important}
    .pl-xs-5,.px-xs-5{padding-left:3rem!important}
    .m-xs-auto{margin:auto!important}
    .mt-xs-auto,.my-xs-auto{margin-top:auto!important}
    .mr-xs-auto,.mx-xs-auto{margin-right:auto!important}
    .mb-xs-auto,.my-xs-auto{margin-bottom:auto!important}
    .ml-xs-auto,.mx-xs-auto{margin-left:auto!important}
}

@media (min-width:576px){
    .m-sm-0{margin:0!important}
    .mt-sm-0,.my-sm-0{margin-top:0!important}
    .mr-sm-0,.mx-sm-0{margin-right:0!important}
    .mb-sm-0,.my-sm-0{margin-bottom:0!important}
    .ml-sm-0,.mx-sm-0{margin-left:0!important}
    .m-sm-1{margin:.25rem!important}
    .mt-sm-1,.my-sm-1{margin-top:.25rem!important}
    .mr-sm-1,.mx-sm-1{margin-right:.25rem!important}
    .mb-sm-1,.my-sm-1{margin-bottom:.25rem!important}
    .ml-sm-1,.mx-sm-1{margin-left:.25rem!important}
    .m-sm-2{margin:.5rem!important}
    .mt-sm-2,.my-sm-2{margin-top:.5rem!important}
    .mr-sm-2,.mx-sm-2{margin-right:.5rem!important}
    .mb-sm-2,.my-sm-2{margin-bottom:.5rem!important}
    .ml-sm-2,.mx-sm-2{margin-left:.5rem!important}
    .m-sm-3{margin:1rem!important}
    .mt-sm-3,.my-sm-3{margin-top:1rem!important}
    .mr-sm-3,.mx-sm-3{margin-right:1rem!important}
    .mb-sm-3,.my-sm-3{margin-bottom:1rem!important}
    .ml-sm-3,.mx-sm-3{margin-left:1rem!important}
    .m-sm-4{margin:1.5rem!important}
    .mt-sm-4,.my-sm-4{margin-top:1.5rem!important}
    .mr-sm-4,.mx-sm-4{margin-right:1.5rem!important}
    .mb-sm-4,.my-sm-4{margin-bottom:1.5rem!important}
    .ml-sm-4,.mx-sm-4{margin-left:1.5rem!important}
    .m-sm-5{margin:3rem!important}
    .mt-sm-5,.my-sm-5{margin-top:3rem!important}
    .mr-sm-5,.mx-sm-5{margin-right:3rem!important}
    .mb-sm-5,.my-sm-5{margin-bottom:3rem!important}
    .ml-sm-5,.mx-sm-5{margin-left:3rem!important}
    .p-sm-0{padding:0!important}
    .pt-sm-0,.py-sm-0{padding-top:0!important}
    .pr-sm-0,.px-sm-0{padding-right:0!important}
    .pb-sm-0,.py-sm-0{padding-bottom:0!important}
    .pl-sm-0,.px-sm-0{padding-left:0!important}
    .p-sm-1{padding:.25rem!important}
    .pt-sm-1,.py-sm-1{padding-top:.25rem!important}
    .pr-sm-1,.px-sm-1{padding-right:.25rem!important}
    .pb-sm-1,.py-sm-1{padding-bottom:.25rem!important}
    .pl-sm-1,.px-sm-1{padding-left:.25rem!important}
    .p-sm-2{padding:.5rem!important}
    .pt-sm-2,.py-sm-2{padding-top:.5rem!important}
    .pr-sm-2,.px-sm-2{padding-right:.5rem!important}
    .pb-sm-2,.py-sm-2{padding-bottom:.5rem!important}
    .pl-sm-2,.px-sm-2{padding-left:.5rem!important}
    .p-sm-3{padding:1rem!important}
    .pt-sm-3,.py-sm-3{padding-top:1rem!important}
    .pr-sm-3,.px-sm-3{padding-right:1rem!important}
    .pb-sm-3,.py-sm-3{padding-bottom:1rem!important}
    .pl-sm-3,.px-sm-3{padding-left:1rem!important}
    .p-sm-4{padding:1.5rem!important}
    .pt-sm-4,.py-sm-4{padding-top:1.5rem!important}
    .pr-sm-4,.px-sm-4{padding-right:1.5rem!important}
    .pb-sm-4,.py-sm-4{padding-bottom:1.5rem!important}
    .pl-sm-4,.px-sm-4{padding-left:1.5rem!important}
    .p-sm-5{padding:3rem!important}
    .pt-sm-5,.py-sm-5{padding-top:3rem!important}
    .pr-sm-5,.px-sm-5{padding-right:3rem!important}
    .pb-sm-5,.py-sm-5{padding-bottom:3rem!important}
    .pl-sm-5,.px-sm-5{padding-left:3rem!important}
    .m-sm-auto{margin:auto!important}
    .mt-sm-auto,.my-sm-auto{margin-top:auto!important}
    .mr-sm-auto,.mx-sm-auto{margin-right:auto!important}
    .mb-sm-auto,.my-sm-auto{margin-bottom:auto!important}
    .ml-sm-auto,.mx-sm-auto{margin-left:auto!important}
}
@media (min-width:768px){
    .m-md-0{margin:0!important}
    .mt-md-0,.my-md-0{margin-top:0!important}
    .mr-md-0,.mx-md-0{margin-right:0!important}
    .mb-md-0,.my-md-0{margin-bottom:0!important}
    .ml-md-0,.mx-md-0{margin-left:0!important}
    .m-md-1{margin:.25rem!important}
    .mt-md-1,.my-md-1{margin-top:.25rem!important}
    .mr-md-1,.mx-md-1{margin-right:.25rem!important}
    .mb-md-1,.my-md-1{margin-bottom:.25rem!important}
    .ml-md-1,.mx-md-1{margin-left:.25rem!important}
    .m-md-2{margin:.5rem!important}
    .mt-md-2,.my-md-2{margin-top:.5rem!important}
    .mr-md-2,.mx-md-2{margin-right:.5rem!important}
    .mb-md-2,.my-md-2{margin-bottom:.5rem!important}
    .ml-md-2,.mx-md-2{margin-left:.5rem!important}
    .m-md-3{margin:1rem!important}
    .mt-md-3,.my-md-3{margin-top:1rem!important}
    .mr-md-3,.mx-md-3{margin-right:1rem!important}
    .mb-md-3,.my-md-3{margin-bottom:1rem!important}
    .ml-md-3,.mx-md-3{margin-left:1rem!important}
    .m-md-4{margin:1.5rem!important}
    .mt-md-4,.my-md-4{margin-top:1.5rem!important}
    .mr-md-4,.mx-md-4{margin-right:1.5rem!important}
    .mb-md-4,.my-md-4{margin-bottom:1.5rem!important}
    .ml-md-4,.mx-md-4{margin-left:1.5rem!important}
    .m-md-5{margin:3rem!important}
    .mt-md-5,.my-md-5{margin-top:3rem!important}
    .mr-md-5,.mx-md-5{margin-right:3rem!important}
    .mb-md-5,.my-md-5{margin-bottom:3rem!important}
    .ml-md-5,.mx-md-5{margin-left:3rem!important}
    .p-md-0{padding:0!important}
    .pt-md-0,.py-md-0{padding-top:0!important}
    .pr-md-0,.px-md-0{padding-right:0!important}
    .pb-md-0,.py-md-0{padding-bottom:0!important}
    .pl-md-0,.px-md-0{padding-left:0!important}
    .p-md-1{padding:.25rem!important}
    .pt-md-1,.py-md-1{padding-top:.25rem!important}
    .pr-md-1,.px-md-1{padding-right:.25rem!important}
    .pb-md-1,.py-md-1{padding-bottom:.25rem!important}
    .pl-md-1,.px-md-1{padding-left:.25rem!important}
    .p-md-2{padding:.5rem!important}
    .pt-md-2,.py-md-2{padding-top:.5rem!important}
    .pr-md-2,.px-md-2{padding-right:.5rem!important}
    .pb-md-2,.py-md-2{padding-bottom:.5rem!important}
    .pl-md-2,.px-md-2{padding-left:.5rem!important}
    .p-md-3{padding:1rem!important}
    .pt-md-3,.py-md-3{padding-top:1rem!important}
    .pr-md-3,.px-md-3{padding-right:1rem!important}
    .pb-md-3,.py-md-3{padding-bottom:1rem!important}
    .pl-md-3,.px-md-3{padding-left:1rem!important}
    .p-md-4{padding:1.5rem!important}
    .pt-md-4,.py-md-4{padding-top:1.5rem!important}
    .pr-md-4,.px-md-4{padding-right:1.5rem!important}
    .pb-md-4,.py-md-4{padding-bottom:1.5rem!important}
    .pl-md-4,.px-md-4{padding-left:1.5rem!important}
    .p-md-5{padding:3rem!important}
    .pt-md-5,.py-md-5{padding-top:3rem!important}
    .pr-md-5,.px-md-5{padding-right:3rem!important}
    .pb-md-5,.py-md-5{padding-bottom:3rem!important}
    .pl-md-5,.px-md-5{padding-left:3rem!important}
    .m-md-auto{margin:auto!important}
    .mt-md-auto,.my-md-auto{margin-top:auto!important}
    .mr-md-auto,.mx-md-auto{margin-right:auto!important}
    .mb-md-auto,.my-md-auto{margin-bottom:auto!important}
    .ml-md-auto,.mx-md-auto{margin-left:auto!important}
}
@media (min-width:992px){
    .m-lg-0{margin:0!important}
    .mt-lg-0,.my-lg-0{margin-top:0!important}
    .mr-lg-0,.mx-lg-0{margin-right:0!important}
    .mb-lg-0,.my-lg-0{margin-bottom:0!important}
    .ml-lg-0,.mx-lg-0{margin-left:0!important}
    .m-lg-1{margin:.25rem!important}
    .mt-lg-1,.my-lg-1{margin-top:.25rem!important}
    .mr-lg-1,.mx-lg-1{margin-right:.25rem!important}
    .mb-lg-1,.my-lg-1{margin-bottom:.25rem!important}
    .ml-lg-1,.mx-lg-1{margin-left:.25rem!important}
    .m-lg-2{margin:.5rem!important}
    .mt-lg-2,.my-lg-2{margin-top:.5rem!important}
    .mr-lg-2,.mx-lg-2{margin-right:.5rem!important}
    .mb-lg-2,.my-lg-2{margin-bottom:.5rem!important}
    .ml-lg-2,.mx-lg-2{margin-left:.5rem!important}
    .m-lg-3{margin:1rem!important}
    .mt-lg-3,.my-lg-3{margin-top:1rem!important}
    .mr-lg-3,.mx-lg-3{margin-right:1rem!important}
    .mb-lg-3,.my-lg-3{margin-bottom:1rem!important}
    .ml-lg-3,.mx-lg-3{margin-left:1rem!important}
    .m-lg-4{margin:1.5rem!important}
    .mt-lg-4,.my-lg-4{margin-top:1.5rem!important}
    .mr-lg-4,.mx-lg-4{margin-right:1.5rem!important}
    .mb-lg-4,.my-lg-4{margin-bottom:1.5rem!important}
    .ml-lg-4,.mx-lg-4{margin-left:1.5rem!important}
    .m-lg-5{margin:3rem!important}
    .mt-lg-5,.my-lg-5{margin-top:3rem!important}
    .mr-lg-5,.mx-lg-5{margin-right:3rem!important}
    .mb-lg-5,.my-lg-5{margin-bottom:3rem!important}
    .ml-lg-5,.mx-lg-5{margin-left:3rem!important}
    .p-lg-0{padding:0!important}
    .pt-lg-0,.py-lg-0{padding-top:0!important}
    .pr-lg-0,.px-lg-0{padding-right:0!important}
    .pb-lg-0,.py-lg-0{padding-bottom:0!important}
    .pl-lg-0,.px-lg-0{padding-left:0!important}
    .p-lg-1{padding:.25rem!important}
    .pt-lg-1,.py-lg-1{padding-top:.25rem!important}
    .pr-lg-1,.px-lg-1{padding-right:.25rem!important}
    .pb-lg-1,.py-lg-1{padding-bottom:.25rem!important}
    .pl-lg-1,.px-lg-1{padding-left:.25rem!important}
    .p-lg-2{padding:.5rem!important}
    .pt-lg-2,.py-lg-2{padding-top:.5rem!important}
    .pr-lg-2,.px-lg-2{padding-right:.5rem!important}
    .pb-lg-2,.py-lg-2{padding-bottom:.5rem!important}
    .pl-lg-2,.px-lg-2{padding-left:.5rem!important}
    .p-lg-3{padding:1rem!important}
    .pt-lg-3,.py-lg-3{padding-top:1rem!important}
    .pr-lg-3,.px-lg-3{padding-right:1rem!important}
    .pb-lg-3,.py-lg-3{padding-bottom:1rem!important}
    .pl-lg-3,.px-lg-3{padding-left:1rem!important}
    .p-lg-4{padding:1.5rem!important}
    .pt-lg-4,.py-lg-4{padding-top:1.5rem!important}
    .pr-lg-4,.px-lg-4{padding-right:1.5rem!important}
    .pb-lg-4,.py-lg-4{padding-bottom:1.5rem!important}
    .pl-lg-4,.px-lg-4{padding-left:1.5rem!important}
    .p-lg-5{padding:3rem!important}
    .pt-lg-5,.py-lg-5{padding-top:3rem!important}
    .pr-lg-5,.px-lg-5{padding-right:3rem!important}
    .pb-lg-5,.py-lg-5{padding-bottom:3rem!important}
    .pl-lg-5,.px-lg-5{padding-left:3rem!important}
    .m-lg-auto{margin:auto!important}
    .mt-lg-auto,.my-lg-auto{margin-top:auto!important}
    .mr-lg-auto,.mx-lg-auto{margin-right:auto!important}
    .mb-lg-auto,.my-lg-auto{margin-bottom:auto!important}
    .ml-lg-auto,.mx-lg-auto{margin-left:auto!important}
}
@media (min-width:1200px){
    .m-xl-0{margin:0!important}
    .mt-xl-0,.my-xl-0{margin-top:0!important}
    .mr-xl-0,.mx-xl-0{margin-right:0!important}
    .mb-xl-0,.my-xl-0{margin-bottom:0!important}
    .ml-xl-0,.mx-xl-0{margin-left:0!important}
    .m-xl-1{margin:.25rem!important}
    .mt-xl-1,.my-xl-1{margin-top:.25rem!important}
    .mr-xl-1,.mx-xl-1{margin-right:.25rem!important}
    .mb-xl-1,.my-xl-1{margin-bottom:.25rem!important}
    .ml-xl-1,.mx-xl-1{margin-left:.25rem!important}
    .m-xl-2{margin:.5rem!important}
    .mt-xl-2,.my-xl-2{margin-top:.5rem!important}
    .mr-xl-2,.mx-xl-2{margin-right:.5rem!important}
    .mb-xl-2,.my-xl-2{margin-bottom:.5rem!important}
    .ml-xl-2,.mx-xl-2{margin-left:.5rem!important}
    .m-xl-3{margin:1rem!important}
    .mt-xl-3,.my-xl-3{margin-top:1rem!important}
    .mr-xl-3,.mx-xl-3{margin-right:1rem!important}
    .mb-xl-3,.my-xl-3{margin-bottom:1rem!important}
    .ml-xl-3,.mx-xl-3{margin-left:1rem!important}
    .m-xl-4{margin:1.5rem!important}
    .mt-xl-4,.my-xl-4{margin-top:1.5rem!important}
    .mr-xl-4,.mx-xl-4{margin-right:1.5rem!important}
    .mb-xl-4,.my-xl-4{margin-bottom:1.5rem!important}
    .ml-xl-4,.mx-xl-4{margin-left:1.5rem!important}
    .m-xl-5{margin:3rem!important}
    .mt-xl-5,.my-xl-5{margin-top:3rem!important}
    .mr-xl-5,.mx-xl-5{margin-right:3rem!important}
    .mb-xl-5,.my-xl-5{margin-bottom:3rem!important}
    .ml-xl-5,.mx-xl-5{margin-left:3rem!important}
    .p-xl-0{padding:0!important}
    .pt-xl-0,.py-xl-0{padding-top:0!important}
    .pr-xl-0,.px-xl-0{padding-right:0!important}
    .pb-xl-0,.py-xl-0{padding-bottom:0!important}
    .pl-xl-0,.px-xl-0{padding-left:0!important}
    .p-xl-1{padding:.25rem!important}
    .pt-xl-1,.py-xl-1{padding-top:.25rem!important}
    .pr-xl-1,.px-xl-1{padding-right:.25rem!important}
    .pb-xl-1,.py-xl-1{padding-bottom:.25rem!important}
    .pl-xl-1,.px-xl-1{padding-left:.25rem!important}
    .p-xl-2{padding:.5rem!important}
    .pt-xl-2,.py-xl-2{padding-top:.5rem!important}
    .pr-xl-2,.px-xl-2{padding-right:.5rem!important}
    .pb-xl-2,.py-xl-2{padding-bottom:.5rem!important}
    .pl-xl-2,.px-xl-2{padding-left:.5rem!important}
    .p-xl-3{padding:1rem!important}
    .pt-xl-3,.py-xl-3{padding-top:1rem!important}
    .pr-xl-3,.px-xl-3{padding-right:1rem!important}
    .pb-xl-3,.py-xl-3{padding-bottom:1rem!important}
    .pl-xl-3,.px-xl-3{padding-left:1rem!important}
    .p-xl-4{padding:1.5rem!important}
    .pt-xl-4,.py-xl-4{padding-top:1.5rem!important}
    .pr-xl-4,.px-xl-4{padding-right:1.5rem!important}
    .pb-xl-4,.py-xl-4{padding-bottom:1.5rem!important}
    .pl-xl-4,.px-xl-4{padding-left:1.5rem!important}
    .p-xl-5{padding:3rem!important}
    .pt-xl-5,.py-xl-5{padding-top:3rem!important}
    .pr-xl-5,.px-xl-5{padding-right:3rem!important}
    .pb-xl-5,.py-xl-5{padding-bottom:3rem!important}
    .pl-xl-5,.px-xl-5{padding-left:3rem!important}
    .m-xl-auto{margin:auto!important}
    .mt-xl-auto,.my-xl-auto{margin-top:auto!important}
    .mr-xl-auto,.mx-xl-auto{margin-right:auto!important}
    .mb-xl-auto,.my-xl-auto{margin-bottom:auto!important}
    .ml-xl-auto,.mx-xl-auto{margin-left:auto!important}
}

/************************************** Spacing *************************************/
.text-justify{text-align:justify!important}
.text-nowrap{white-space:nowrap!important}
.text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.pull-left{float:left !important;}
.pull-right{float:right !important;}
.text-left{text-align:left!important}
.text-right{text-align:right!important}
.text-center{text-align:center!important}
@media (min-width:576px){
    .text-sm-left{text-align:left!important}
    .text-sm-right{text-align:right!important}
    .text-sm-center{text-align:center!important}
}
@media (min-width:768px){
    .text-md-left{text-align:left!important}
    .text-md-right{text-align:right!important}
    .text-md-center{text-align:center!important}
}
@media (min-width:992px){
    .text-lg-left{text-align:left!important}
    .text-lg-right{text-align:right!important}
    .text-lg-center{text-align:center!important}
}
@media (min-width:1200px){
    .text-xl-left{text-align:left!important}
    .text-xl-right{text-align:right!important}
    .text-xl-center{text-align:center!important}
}
.text-lowercase{text-transform:lowercase!important}
.text-uppercase{text-transform:uppercase!important}
.text-capitalize{text-transform:capitalize!important}
.font-weight-light{font-weight:300!important}
.font-weight-normal{font-weight:400!important}
.font-weight-bold{font-weight:700!important}
.font-italic{font-style:italic!important}
.text-white{color:#fff!important}
.text-primary{color:#007bff!important}
a.text-primary:focus,a.text-primary:hover{color:#0062cc!important}
.text-secondary{color:#6c757d!important}
a.text-secondary:focus,a.text-secondary:hover{color:#545b62!important}
.text-success{color:#28a745!important}
a.text-success:focus,a.text-success:hover{color:#1e7e34!important}
.text-info{color:#17a2b8!important}
a.text-info:focus,a.text-info:hover{color:#117a8b!important}
.text-warning{color:#ffc107!important}
a.text-warning:focus,a.text-warning:hover{color:#d39e00!important}
.text-danger{color:#dc3545!important}
a.text-danger:focus,a.text-danger:hover{color:#bd2130!important}
.text-light{color:#f8f9fa!important}
a.text-light:focus,a.text-light:hover{color:#dae0e5!important}
.text-dark{color:#343a40!important}
a.text-dark:focus,a.text-dark:hover{color:#1d2124!important}
.text-body{color:#212529!important}
.text-muted{color:#6c757d!important}
.text-black-50{color:rgba(0,0,0,.5)!important}
.text-white-50{color:rgba(255,255,255,.5)!important}

/******************************** Vertical alignment *******************************/
.align-baseline{vertical-align:baseline!important}
.align-top{vertical-align:top!important}
.align-middle{vertical-align:middle!important}
.align-bottom{vertical-align:bottom!important}
.align-text-bottom{vertical-align:text-bottom!important}
.align-text-top{vertical-align:text-top!important}

/******************************** Visibility *******************************/
.visible{visibility:visible !important;}
.invisible{visibility:hidden !important;}

/******************************** stepper *******************************/
.step-three,.step-two{clip:rect(0,0,25px,0)}
.slider-form h2,.steps>div>span{font-weight:300}
.steps{margin-bottom:10px;position:relative;height:25px}
.steps>div{position:absolute;top:0;-webkit-transform:translate(-50%);-ms-transform:translate(-50%);transform:translate(-50%);height:25px;padding:0 5px;display:inline-block;width:80px;text-align:center;-webkit-transition:.3s all ease;transition:.3s all ease}
.steps>div>span{line-height:25px;height:25px;margin:0;color:#777;font-family:Roboto,sans-serif;font-size:.9rem}
.steps>div>.liner{position:absolute;height:2px;width:0%;left:0;top:50%;margin-top:-1px;background:#999;-webkit-transition:.3s all ease;transition:.3s all ease}
.step-one{left:0}
.step-two{left:50%}
.step-three{left:100%}
.line{width:100%;height:4px;background:#ddd;position:relative;border-radius:10px;overflow:visible;margin-bottom:50px}
.line .dot,.line .dot-move{top:50%;width:15px;height:15px;background:#ddd;border-radius:50%;left:0;position:absolute}
.line .dot-move{-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-webkit-transition:.3s all ease;transition:.3s all ease}
.line .dot{-webkit-transition:.3s all ease;transition:.3s all ease;-webkit-transform:translate(-50%,-50%) scale(.5);-ms-transform:translate(-50%,-50%) scale(.5);transform:translate(-50%,-50%) scale(.5)}
.slider-form h2,.slider-form h3{font-size:1.5rem;font-family:Roboto,sans-serif;color:#999;position:relative;margin-bottom:50px}
.line .dot.zero{left:0;background:#bbb}
.container.slider-one-active .dot.zero{background:#5892fc}
.line .dot.center{left:50%;background:#bbb}
.line .dot.full{left:100%;background:#bbb}
.slider-ctr{width:100%;overflow:hidden}
.slider{overflow:hidden;width:1200px;-webkit-transition:.3s all ease;transition:.3s all ease;-webkit-transform:translate(0) scale(1);-ms-transform:translate(0) scale(1);transform:translate(0) scale(1)}
.container.slider-one-active .slider-three,.container.slider-one-active .slider-two,.container.slider-three-active .slider-one,.container.slider-three-active .slider-two,.container.slider-two-active .slider-one,.container.slider-two-active .slider-three{-webkit-transform:scale(.5);-ms-transform:scale(.5);transform:scale(.5)}
.slider-one,.slider-three,.slider-two{-webkit-transition:.3s all ease;transition:.3s all ease}
.slider-form{width:500px;text-align:center;margin:0 auto;}
.slider-form h2 .yourname{font-weight:400}
.slider-form h3{font-weight:300;line-height:1.5}
.slider-form h3 .balapa{font-family:Pacifico,sans-serif;display:inline-block;color:#5892fc;text-decoration:none}
.reset,.slider-form [type=text],.slider-form button{font-size:1rem;font-family:Roboto,sans-serif;position:relative}
.slider-form [type=text]{width:100%;box-sizing:border-box;padding:15px 20px;background:#fafafa;border:1px solid transparent;color:#777;border-radius:50px;margin-bottom:50px;z-index:99}
.slider-form [type=text]:focus{background:#fcfcfc;border:1px solid #ddd}
.reset,.slider-form button{display:inline-block;text-decoration:none;background:#5892fc;border:none;color:#fff;padding:10px 25px;border-radius:3px;cursor:pointer;font-weight:300}
.label-ctr{margin-bottom:50px;}
.container.center .line .dot-move{left:50%;-webkit-animation:.3s anim 1;}
.container.center .slider{-webkit-transform:translate(-400px);-ms-transform:translate(-400px);transform:translate(-400px)}
.container.full .line .dot-move{left:100%;-webkit-animation:.3s anim 1;}
.container.full .slider{-webkit-transform:translate(-800px);-ms-transform:translate(-800px);transform:translate(-800px);}

/******************************** DatePicker *******************************/
.bp3-datepicker{position:relative;border-radius:3px;background:#fff;padding:5px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.bp3-datepicker .DayPicker{display:inline-block;position:relative;min-width:210px;vertical-align:top}
.bp3-datepicker .DayPicker:focus{outline:none}
.bp3-datepicker .DayPicker-Month{display:inline-table;margin:0 5px 5px;border-collapse:collapse;border-spacing:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.bp3-datepicker .DayPicker-Month+.bp3-datepicker .DayPicker-Month{margin-left:10px}
.bp3-datepicker .DayPicker-Caption{display:table-caption}
.bp3-datepicker .DayPicker-Weekdays{display:table-header-group}
.bp3-datepicker .DayPicker-WeekdaysRow{display:table-row}
.bp3-datepicker .DayPicker-Weekday{display:table-cell;width:30px;height:30px;vertical-align:middle;text-align:center;line-height:1;padding-top:5px;font-weight:600}
.bp3-datepicker .DayPicker-Weekday abbr[title]{text-decoration:none}
.bp3-datepicker .DayPicker-Body{display:table-row-group}
.bp3-datepicker .DayPicker-Week{display:table-row}
.bp3-datepicker .DayPicker-WeekNumber{color:rgba(92,112,128,.5);font-size:14px}
.bp3-datepicker .DayPicker-Day,.bp3-datepicker .DayPicker-WeekNumber{display:table-cell;width:30px;height:30px;vertical-align:middle;text-align:center;line-height:1}
.bp3-datepicker .DayPicker-Day{border-radius:3px;cursor:pointer}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--outside{color:rgba(92,112,128,.5)}
.bp3-datepicker .DayPicker-Day:focus,.bp3-datepicker .DayPicker-Day:hover{background:#d8e1e8;color:#182026}
.bp3-datepicker .DayPicker-Day:active{background:#5892FC}
.bp3-datepicker .DayPicker-Day:focus, .bp3-datepicker .DayPicker-Day:hover{background:#5892FC;color:#fff;}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected{border-radius:3px;background-color:#236AEE;color:#fff}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover{background-color:#106ba3}
.bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled{background:none;cursor:not-allowed;color:rgba(92,112,128,.5)}
.bp3-datepicker-navbar{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;top:0;right:0;left:0;height:30px}
.bp3-datepicker-navbar>:first-child{margin-right:auto}
.bp3-datepicker-caption{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin:0 25px 5px}
.bp3-datepicker-caption>*{-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;-ms-flex-negative:0;flex-shrink:0}
.bp3-datepicker-caption>:first-child{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:1;flex-shrink:1}
.bp3-datepicker-caption select{padding-right:16px;padding-left:5px;font-weight:600}
.bp3-datepicker-caption select+.bp3-icon{right:2px}
.bp3-datepicker-caption+.bp3-divider{margin:0}
.bp3-datepicker-month-select,.bp3-datepicker-year-select{-ms-flex-negative:1;flex-shrink:1}
.bp3-datepicker-year-select{min-width:60px}
.bp3-datepicker-caption-measure{padding-left:5px;font-weight:600}
.bp3-datepicker-footer{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.bp3-dark .bp3-datepicker{background:#30404d}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--outside,.bp3-dark .bp3-datepicker .DayPicker-WeekNumber{color:rgba(191,204,214,.5)}
.bp3-dark .bp3-datepicker .DayPicker-Day:focus,.bp3-dark .bp3-datepicker .DayPicker-Day:hover{background:#5c7080;color:#fff}
.bp3-dark .bp3-datepicker .DayPicker-Day:active{background:#738694}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected{background-color:#137cbd}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover{background-color:#2b95d6}
.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled{background:none;color:rgba(191,204,214,.5)}
.bp3-dark .bp3-datepicker .bp3-datepicker-footer{border-top-color:rgba(16,22,26,.4)}
.bp3-daterangepicker{display:-webkit-box;display:-ms-flexbox;display:flex;white-space:nowrap}
.bp3-daterangepicker .DayPicker:first-of-type .bp3-datepicker-navbar{left:5px}
.bp3-daterangepicker .DayPicker-NavButton--interactionDisabled{display:none}
.bp3-daterangepicker .bp3-daterangepicker-timepickers{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around}
.bp3-daterangepicker .bp3-daterangepicker-shortcuts+.DayPicker{border-left:1px solid rgba(16,22,26,.15);min-width:220px;padding-left:5px}
.bp3-daterangepicker.bp3-daterangepicker-contiguous .DayPicker{min-width:430px}
.bp3-daterangepicker.bp3-daterangepicker-contiguous .bp3-daterangepicker-shortcuts+.DayPicker{min-width:440px}
.bp3-daterangepicker.bp3-daterangepicker-single-month .DayPicker{min-width:210px}
.bp3-daterangepicker.bp3-daterangepicker-single-month .bp3-daterangepicker-shortcuts+.DayPicker{min-width:220px}
.bp3-daterangepicker .DayPicker-Day--outside{visibility:hidden}
.bp3-daterangepicker .DayPicker-Day--hovered-range{border-radius:0}
.bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end){background-color:#ebf1f5}
.bp3-daterangepicker .DayPicker-Day--selected-range{border-radius:0;background-color:#ebf1f5}
.bp3-daterangepicker .DayPicker-Day--selected-range:hover{background-color:#ced9e0}
.bp3-daterangepicker .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end){border-top-right-radius:0;border-bottom-right-radius:0}
.bp3-daterangepicker .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start){border-top-left-radius:0;border-bottom-left-radius:0}
.bp3-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end){border-top-right-radius:0;border-bottom-right-radius:0}
.bp3-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start){border-top-left-radius:0;border-bottom-left-radius:0}
.bp3-dark .bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end),.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range{background-color:#394b59}
.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range:hover{background-color:#5c7080}
.bp3-menu.bp3-daterangepicker-shortcuts{min-width:120px;padding:0}
.bp3-timepicker{white-space:nowrap;padding:10px 20px;}
.bp3-timepicker .bp3-timepicker-arrow-row{padding:0 1px}
.bp3-timepicker .bp3-timepicker-arrow-button{color:#5c7080;display:inline-block;width:33px;padding:4px 0;text-align:center}
.bp3-timepicker .bp3-timepicker-arrow-button:hover{color:#182026}
.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button{color:#bfccd6}
.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button:hover{color:#f5f8fa}
.bp3-timepicker .bp3-timepicker-arrow-button+.bp3-timepicker-arrow-button{margin-left:11px}
.bp3-timepicker .bp3-timepicker-arrow-button:hover{cursor:pointer}
.bp3-timepicker .bp3-timepicker-input-row{display:inline-block;border-radius:3px;-webkit-box-shadow:inset 0 0 0 1px rgba(16,22,26,.15),inset 0 1px 1px rgba(16,22,26,.2);box-shadow:inset 0 0 0 1px rgba(16,22,26,.15),inset 0 1px 1px rgba(16,22,26,.2);background:#fff;height:30px;padding:0 1px;vertical-align:middle;line-height:28px}
.bp3-timepicker .bp3-timepicker-divider-text{display:inline-block;width:11px;text-align:center;color:#5c7080;font-size:16px}
.bp3-timepicker .bp3-timepicker-input{outline:0;border:0;border-radius:3px;-webkit-box-shadow:0 0 0 0 rgba(19,124,189,0),0 0 0 0 rgba(19,124,189,0);box-shadow:0 0 0 0 rgba(19,124,189,0),0 0 0 0 rgba(19,124,189,0);background:transparent;width:33px;height:28px;padding:0;text-align:center;color:#182026;-webkit-transition:-webkit-box-shadow .1s cubic-bezier(.4,1,.75,.9);transition:-webkit-box-shadow .1s cubic-bezier(.4,1,.75,.9);transition:box-shadow .1s cubic-bezier(.4,1,.75,.9);transition:box-shadow .1s cubic-bezier(.4,1,.75,.9),-webkit-box-shadow .1s cubic-bezier(.4,1,.75,.9)}
.bp3-timepicker .bp3-timepicker-input:focus{-webkit-box-shadow:0 0 0 1px #236AEE, 0 0 0 3px rgba(35, 106, 238, 0.3);box-shadow:0 0 0 1px #236AEE, 0 0 0 3px rgba(35, 106, 238, 0.3)}
.bp3-timepicker .bp3-timepicker-ampm-select{margin-left:5px}
.bp3-timepicker.bp3-disabled .bp3-timepicker-input-row{-webkit-box-shadow:none;box-shadow:none;background:rgba(206,217,224,.5);cursor:not-allowed;color:rgba(92,112,128,.5);resize:none}
.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button,.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button:hover,.bp3-timepicker.bp3-disabled .bp3-timepicker-divider-text,.bp3-timepicker.bp3-disabled .bp3-timepicker-input{cursor:not-allowed;color:rgba(92,112,128,.5)}
.bp3-dark .bp3-timepicker .bp3-timepicker-input-row{-webkit-box-shadow:inset 0 0 0 1px rgba(16,22,26,.3),inset 0 1px 1px rgba(16,22,26,.4);box-shadow:inset 0 0 0 1px rgba(16,22,26,.3),inset 0 1px 1px rgba(16,22,26,.4);background:rgba(16,22,26,.3)}
.bp3-dark .bp3-timepicker .bp3-timepicker-divider-text{color:#bfccd6}
.bp3-dark .bp3-timepicker .bp3-timepicker-input{color:#f5f8fa}
.bp3-datepicker .bp3-timepicker{margin-top:5px;margin-bottom:10px;text-align:center}
.bp3-datepicker .bp3-timepicker:last-child{margin-bottom:5px}
.bp3-datetimepicker{border-radius:3px;background-color:#fff;padding:10px;text-align:center;}
.bp3-dark .bp3-datetimepicker{background:#30404d}
.bp3-dark .bp3-datetimepicker .bp3-datepicker{border-bottom:1px solid rgba(16,22,26,.4)}
.bp3-datetimepicker .bp3-datepicker{border-bottom:1px solid rgba(16,22,26,.15);padding:0 0 10px}
.bp3-datetimepicker .bp3-timepicker{margin-top:10px}
.bp3-dateinput-popover{padding:0}
.bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end){background: rgba(88, 146, 252, 0.15) !important;}

/******************************** Navbar *******************************/
/* .bp3-navbar{position:relative;z-index:10;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);background-color:#fff;width:100%;height:60px;padding:0 15px}
.bp3-blue .bp3-navbar,.bp3-navbar.bp3-blue{background-color:#236AEE;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);}
.bp3-navbar.bp3-blue{box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);}
.bp3-blue .bp3-navbar{box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);}
.bp3-navbar.bp3-fixed-top{position:fixed;top:0;right:0;left:0}
.bp3-navbar-heading{margin-right:15px;font-size:16px}
.bp3-navbar-group{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:50px}
.bp3-navbar-group.bp3-align-left{float:left}
.bp3-navbar-group.bp3-align-right{float:right}
.bp3-navbar-divider{margin:0 10px;border-left:1px solid rgba(16,22,26,.15);height:20px}
.bp3-navbar, ul.bp3-navbar-group{margin:0;padding:0;display: flex;}
.bp3-navbar, ul.bp3-navbar-group li{vertical-align: middle;}
.bp3-navbar,ul.bp3-navbar-group li a{box-shadow:none;padding:4px 17px;font-size:13px;font-weight:400;line-height:16px;text-align:left;white-space:nowrap;color:#fff;transition:.3s;-webkit-transition:.3s}
.bp3-navbar-container{margin:0 auto;max-width:1140px;width:100%}
.bp3-navbar .bp3-input-group .bp3-button,.bp3-navbar .bp3-input-group .bp3-button::before{margin-top:0}
.bp3-navbar .bp3-input-group{position:relative;width:100%;margin-top:10px}
.bp3-navbar input.bp3-input{background-color:rgba(255,255,255,.2);height:30px;color:#fff;border-radius:2px;border:none;font-size:13px}
.bp3-navbar input.bp3-input:hover{border:none;box-shadow:none}
.bp3-navbar input.bp3-input:focus{border:none;box-shadow:none;outline:0}
.bp3-navbar .bp3-input-group .bp3-button{background-color:rgba(255,255,255,.2);width:35px;height:30px;border:none;color:#fff!important;padding:0 8px!important;margin:0;border-radius:0 3px 3px 0}
.bp3-navbar .bp3-input-group .bp3-button:focus,.bp3-navbar .bp3-input-group .bp3-button:hover{background:#457EE7!important;border-radius:3px}
.bp3-navbar,ul.bp3-navbar-group li a span.bp3-icon:empty{line-height:15px!important}
.bp3-navbar,ul.bp3-navbar-group li a span.bp3-icon:empty:hover{color:#fff!important}
.bp3-navbar,ul.bp3-navbar-group li a:hover{color:#fff!important;transition:.3s;-webkit-transition:.3s}
.bp3-navbar .bp3-input-group input::placeholder{color:#fff!important} */

/******************************** Cart - Dropdown *******************************/
ul.dropdown-cart{min-width:230px;padding:0;}
ul.dropdown-cart li.title {color:#383838;}
ul.dropdown-cart li a {color:#727272;}
ul.dropdown-cart li.highlight a {color:#383838;}
ul.dropdown-cart li .item{display:block;padding:3px 10px;margin:3px 0}
ul.dropdown-cart li .item:hover{background-color:#f3f3f3}
ul.dropdown-cart li .item:after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0}
ul.dropdown-cart li .item-left,ul.dropdown-cart li .item-left img,ul.dropdown-cart li .item-left span.item-info{float:left}
ul.dropdown-cart li .item-left span.item-info{margin-left:10px}
ul.dropdown-cart li .item-left span.item-info span{display:block}
ul.dropdown-cart li .item-right{float:right;font-weight: 500;}
ul.dropdown-cart li .item-right button{margin-top:8px}
a span.item_count{background: #fe5f38 none repeat scroll 0 0;border-radius: 100%;color: #ffffff;display: block;font-size: 12px;font-weight: 500;height: 22px;right: 17px;line-height: 22px;overflow: hidden;position: absolute;text-align: center;top: 5px;width: 22px; right: -5px}

/******************************** MegaMenu - Dropdown *******************************/
/* ul.dropdown-megamenu{min-width:300px;padding:2em 0em 0em 0em;margin:0;background:#FEFEFE;border-radius:2px;}
ul.dropdown-megamenu li.view_more{background:#E8E8E8 !important;text-align:center;padding:1em 0em;font-size:14px;border-radius:2px;}
ul.dropdown-megamenu li.view_more a{color:#444444;font-weight:600;letter-spacing:0.3px;}
ul.dropdown-megamenu li p{margin-top:10px;} */


/******************************** Custom Scrollbar **********************************/
::-webkit-scrollbar {
   width: 6px;
}
::-webkit-scrollbar-thumb {
   //background: -webkit-linear-gradient(right top, #00C9AB, #00BBD0) !important;
   background: #236AEE !important;
   border-radius: 0px;
}
::-webkit-scrollbar-track {
   //box-shadow: inset 0 0 5px grey;
   border-radius: 0px;
}
::selection {
   background: #236AEE;
   color: #fff;
   text-shadow: none;
}
