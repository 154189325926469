.bp3-dialog .bp3-dialog-body.location-selector {
  margin: 2em 4em; }

.bp3-portal .bp3-overlay.bp3-overlay-scroll-container .bp3-dialog-container {
  min-height: 38% !important; }
  .bp3-portal .bp3-overlay.bp3-overlay-scroll-container .bp3-dialog-container .bp3-dialog .bp3-dialog-body .bp3-input {
    box-shadow: none !important; }
    .bp3-portal .bp3-overlay.bp3-overlay-scroll-container .bp3-dialog-container .bp3-dialog .bp3-dialog-body .bp3-input:hover {
      border-color: #236AEE;
      box-shadow: none !important; }
    .bp3-portal .bp3-overlay.bp3-overlay-scroll-container .bp3-dialog-container .bp3-dialog .bp3-dialog-body .bp3-input:focus {
      border-color: #236AEE;
      box-shadow: none !important; }
    .bp3-portal .bp3-overlay.bp3-overlay-scroll-container .bp3-dialog-container .bp3-dialog .bp3-dialog-body .bp3-input:active {
      border-color: #236AEE;
      box-shadow: none !important; }

.bp3-popover.cart-menu {
  width: 270px;
  margin-top: 4px !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.bp3-popover.gg-notifications {
  width: 350px;
  margin-top: 4px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list {
    max-width: 350px;
    max-height: 460px;
    overflow: auto;
    padding-left: 0;
    margin-bottom: 0;
    background: #F5F5F5; }
    .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list::-webkit-scrollbar {
      width: 4px !important; }
    .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list::-webkit-scrollbar-thumb {
      background: #CBCBCB !important;
      border-radius: 10px;
      width: 4px !important; }
    .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item.unread {
      background: #F7FBFF; }
    .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item {
      padding: 8px 15px;
      line-height: 18px;
      text-align: left;
      display: block;
      margin: 0px 0px;
      background: #ffffff;
      border-bottom: 1px solid #E3F2FD;
      border-radius: 2px; }
      .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item:hover {
        background: #e1edf9;
        border-color: #e1edf9; }
      .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .title-head {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        justify-content: flex-start; }
        .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .title-head .title {
          font-weight: 500;
          margin-bottom: 0px;
          font-size: 14px;
          color: #383838;
          letter-spacing: 0;
          line-height: 16px;
          padding-right: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex;
          align-items: center; }
          .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .title-head .title span.bp3-icon {
            font-size: 18px;
            color: #62A31B; }
      .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .timestamp {
        color: #3F5662;
        font-size: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        white-space: nowrap; }
      .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .body {
        margin-bottom: 0px;
        font-size: 12px;
        color: #727272;
        letter-spacing: 0;
        line-height: 17px;
        text-align: justify; }
      .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .item .bp3-button {
        line-height: 17px;
        margin-bottom: 0.8em;
        padding: .15rem 1.4rem; }
  .bp3-popover.gg-notifications .bp3-popover-content .gg-notifications-header {
    line-height: 0;
    display: flex;
    padding: 0px 15px;
    align-items: center;
    justify-content: space-between; }
    .bp3-popover.gg-notifications .bp3-popover-content .gg-notifications-header .note-title {
      font-size: 16px;
      color: #383838;
      letter-spacing: 0;
      text-align: center;
      line-height: 16px;
      margin-bottom: 0; }
    .bp3-popover.gg-notifications .bp3-popover-content .gg-notifications-header a {
      display: inline-block;
      outline: none;
      font-size: 12px;
      color: #236AEE;
      line-height: 16px;
      padding: 15px 15px;
      text-decoration: none !important; }
  .bp3-popover.gg-notifications .bp3-popover-content .gg-notifications-footer {
    text-align: center;
    line-height: 0; }
    .bp3-popover.gg-notifications .bp3-popover-content .gg-notifications-footer a {
      display: inline-block;
      width: 100%;
      outline: none;
      font-size: 14px;
      color: #236AEE;
      padding: 15px 15px;
      letter-spacing: 0;
      text-align: center;
      line-height: 16px;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      text-decoration: none !important; }
      .bp3-popover.gg-notifications .bp3-popover-content .gg-notifications-footer a:hover {
        color: #fff;
        background: #236AEE;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s; }

.header-bar {
  position: sticky;
  top: 0px;
  z-index: 12;
  /******************************** MegaMenu - Dropdown *******************************/
  /******* Header ********/ }
  .header-bar .bp3-navbar {
    position: relative;
    z-index: 10;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    width: 100%;
    height: 60px;
    padding: 0 15px; }
  .header-bar .bp3-navbar.bp3-blue {
    background-color: #236AEE;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3); }
  .header-bar .bp3-navbar.bp3-dark-blue {
    background-color: #0548C4;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3); }
  .header-bar .bp3-navbar.bp3-transparent-blue {
    background-color: transparent;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3); }
  .header-bar .bp3-blue .bp3-navbar {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3); }
  .header-bar .bp3-navbar.bp3-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0; }
  .header-bar .bp3-navbar-heading {
    margin-right: 15px;
    font-size: 16px; }
  .header-bar .bp3-navbar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px; }
    .header-bar .bp3-navbar-group.bp3-align-left {
      float: left; }
    .header-bar .bp3-navbar-group.bp3-align-right {
      float: right; }
  .header-bar .bp3-navbar-divider {
    margin: 0 10px;
    border-left: 1px solid rgba(16, 22, 26, 0.15);
    height: 20px; }
  .header-bar .bp3-navbar, .header-bar ul.bp3-navbar-group {
    margin: 0;
    padding: 0;
    display: flex; }
  .header-bar .bp3-navbar, .header-bar ul.bp3-navbar-group li {
    vertical-align: middle; }
  .header-bar .bp3-navbar, .header-bar ul.bp3-navbar-group li a {
    display: flex;
    align-items: center;
    box-shadow: none;
    padding: 4px 17px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    color: #fff;
    transition: .3s;
    -webkit-transition: .3s; }
  .header-bar .bp3-navbar-container {
    margin: 0 auto;
    max-width: 1140px;
    width: 100%; }
  .header-bar .bp3-navbar {
    line-height: 15px !important; }
    .header-bar .bp3-navbar .bp3-input-group {
      position: relative;
      width: 100%;
      margin-top: 10px; }
      .header-bar .bp3-navbar .bp3-input-group .bp3-button {
        margin-top: 0; }
        .header-bar .bp3-navbar .bp3-input-group .bp3-button::before {
          margin-top: 0; }
    .header-bar .bp3-navbar input.bp3-input {
      background-color: rgba(255, 255, 255, 0.2);
      height: 30px;
      color: #fff;
      border-radius: 2px;
      border: none;
      font-size: 13px; }
      .header-bar .bp3-navbar input.bp3-input:hover {
        border: none;
        box-shadow: none;
        color: #fff !important; }
      .header-bar .bp3-navbar input.bp3-input:focus {
        border: none;
        box-shadow: none;
        outline: 0;
        color: #fff !important; }
      .header-bar .bp3-navbar input.bp3-input:active {
        color: #fff !important; }
    .header-bar .bp3-navbar .bp3-input-group .bp3-button {
      background-color: rgba(255, 255, 255, 0.2) !important;
      width: 35px;
      height: 30px;
      border: none;
      color: #fff !important;
      padding: 0 8px !important;
      margin: 0;
      border-radius: 0 3px 3px 0; }
      .header-bar .bp3-navbar .bp3-input-group .bp3-button:focus, .header-bar .bp3-navbar .bp3-input-group .bp3-button:hover {
        background: #457EE7 !important;
        border-radius: 3px; }
  .header-bar ul.bp3-navbar-group li a span.bp3-icon:empty {
    line-height: 15px !important; }
  .header-bar .bp3-navbar, .header-bar ul.bp3-navbar-group li a span.bp3-icon:empty:hover {
    color: #fff !important; }
  .header-bar .bp3-navbar, .header-bar ul.bp3-navbar-group li a:hover {
    color: #fff !important;
    transition: .3s;
    -webkit-transition: .3s; }
  .header-bar .bp3-navbar .bp3-input-group input::placeholder {
    color: #fff !important; }
  .header-bar .gg-location {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: initial; }
    .header-bar .gg-location .header-profile-name {
      max-width: 200px;
      color: #fff;
      font-size: 12.5px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-align: right; }
    .header-bar .gg-location .header-location-text {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .header-bar .gg-location .header-location-text:span.bp3-icon {
        color: #fff !important; }
  .header-bar .icon-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: initial; }
  .header-bar .retail-header .bp3-popover {
    width: 320px; }
  .header-bar .retail-header .gg-mega-menu {
    background: #FEFEFE; }
  .header-bar .retail-header .gg-notifications {
    position: relative; }
    .header-bar .retail-header .gg-notifications .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12%;
      font-size: 0.6rem;
      text-align: center;
      background: #FE6527;
      color: #ffffff;
      line-height: 17px;
      padding: 0px 3px !important; }
    .header-bar .retail-header .gg-notifications .badge {
      position: absolute;
      top: -7px;
      right: 10px;
      font-size: 11px; }

/*****************GG-MEGA-MENU*********************************/
.gg-mega-menu-wrapper {
  margin-top: 8px !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .gg-mega-menu-wrapper ul.dropdown-megamenu {
    margin: 0;
    min-width: 300px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #FEFEFE;
    border-radius: 2px;
    padding: 2em 0em 0em 0em; }
    .gg-mega-menu-wrapper ul.dropdown-megamenu::-webkit-scrollbar {
      width: 4px !important; }
    .gg-mega-menu-wrapper ul.dropdown-megamenu::-webkit-scrollbar-thumb {
      background: #236AEE !important;
      border-radius: 10px;
      width: 4px !important; }
    .gg-mega-menu-wrapper ul.dropdown-megamenu li.view_more {
      background: #E8E8E8 !important;
      text-align: center;
      padding: 1em 0em;
      font-size: 14px;
      border-radius: 2px; }
      .gg-mega-menu-wrapper ul.dropdown-megamenu li.view_more a {
        color: #444444;
        font-weight: 600;
        letter-spacing: 0.3px; }
    .gg-mega-menu-wrapper ul.dropdown-megamenu li p {
      margin-top: 10px;
      white-space: nowrap; }
      .gg-mega-menu-wrapper ul.dropdown-megamenu li p:hover {
        color: #236AEE; }
  .gg-mega-menu-wrapper .bp3-button {
    padding: 7px 10px; }

/*****************After Login Menu*********************************/
.bp3-popover.login-menu {
  width: 230px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button {
    width: 100%;
    font-size: 14px;
    border: none;
    box-shadow: none;
    color: #383838 !important; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button .bp3-button-text {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button:hover {
      border: none;
      box-shadow: none;
      background-image: none;
      background-color: rgba(167, 182, 194, 0.3) !important; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button:active {
      border: none;
      background-color: #236AEE !important;
      box-shadow: none;
      color: #fff !important; }
      .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button:active span.bp3-icon {
        color: #fff !important; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button:focus {
      border: none;
      box-shadow: none; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button:visited {
      border: none;
      box-shadow: none; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-button span.bp3-icon {
      font-size: 16px; }
  .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-collapse {
    height: auto; }
    .bp3-popover.login-menu .bp3-popover-content ul.bp3-menu.dropdown_menu .bp3-collapse .bp3-collapse-body pre {
      margin: 5px 10px 5px 15px;
      border-top: 1px solid #f1f1f2;
      border-bottom: 1px solid #f1f1f2; }

/*Primary Footer*/
.footer {
  box-shadow: rgba(46, 61, 73, 0.2) 0px 0px 25px 0;
  float: left;
  width: 100%;
  font-family: "Rubik", sans-serif !important; }
  .footer .container--footer-primary, .footer .container--footer-secondry {
    background: #3e3e3e;
    padding: 50px 100px 40px;
    display: table;
    padding-left: 15px;
    padding-right: 15px; }
    .footer .container--footer-primary .app-links a img, .footer .container--footer-secondry .app-links a img {
      width: 100%; }
    .footer .container--footer-primary .footer-parts .bdr-right, .footer .container--footer-secondry .footer-parts .bdr-right {
      height: 50px;
      border-right: 2px solid #727272; }
    .footer .container--footer-primary .footer-parts .company-logo, .footer .container--footer-secondry .footer-parts .company-logo {
      max-width: 12%;
      flex: 0 0 12%;
      padding: 0px 15px; }
    .footer .container--footer-primary .footer-parts .company-address, .footer .container--footer-secondry .footer-parts .company-address {
      max-width: 24%;
      flex: 0 0 24%;
      padding: 0px 15px; }
      .footer .container--footer-primary .footer-parts .company-address .address, .footer .container--footer-secondry .footer-parts .company-address .address {
        color: #CBCBCB;
        font-size: 11px;
        font-weight: 300 !important;
        line-height: 18px;
        text-align: left; }
        .footer .container--footer-primary .footer-parts .company-address .address b, .footer .container--footer-secondry .footer-parts .company-address .address b {
          font-weight: 500 !important; }
    .footer .container--footer-primary .footer-parts .company-email, .footer .container--footer-secondry .footer-parts .company-email {
      max-width: 18%;
      flex: 0 0 18%;
      padding: 0px 15px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column; }
      .footer .container--footer-primary .footer-parts .company-email .contact, .footer .container--footer-secondry .footer-parts .company-email .contact {
        color: #CBCBCB;
        font-size: 12px;
        line-height: 24px;
        text-align: left;
        display: flex;
        margin-bottom: 5px;
        justify-content: center;
        align-items: center; }
        .footer .container--footer-primary .footer-parts .company-email .contact:hover, .footer .container--footer-primary .footer-parts .company-email .contact:active, .footer .container--footer-primary .footer-parts .company-email .contact:focus, .footer .container--footer-secondry .footer-parts .company-email .contact:hover, .footer .container--footer-secondry .footer-parts .company-email .contact:active, .footer .container--footer-secondry .footer-parts .company-email .contact:focus {
          color: #fff !important; }
    .footer .container--footer-primary .footer-parts .company-social, .footer .container--footer-secondry .footer-parts .company-social {
      max-width: 21%;
      flex: 0 0 21%;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .footer .container--footer-primary .footer-parts .company-social .footer-social-link-wrapper .insta img, .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .insta img {
        width: 15%; }
      .footer .container--footer-primary .footer-parts .company-social .footer-social-link-wrapper .youtube img, .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .youtube img {
        width: 15%; }
      .footer .container--footer-primary .footer-parts .company-social .footer-social-link-wrapper .twitter img, .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .twitter img {
        width: 15%; }
      .footer .container--footer-primary .footer-parts .company-social .footer-social-link-wrapper .facebook img, .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .facebook img {
        width: 15%; }
    .footer .container--footer-primary .footer-parts .company-app, .footer .container--footer-secondry .footer-parts .company-app {
      width: 25%;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: center; }

.feature-card-wrapper {
  display: table-row;
  width: 100%;
  margin: 0; }

.feature-card {
  background: #fff;
  border-right: 1px solid #F2F5F6;
  float: none;
  display: table-cell;
  vertical-align: top;
  padding: 30px 15px;
  text-align: center; }

.feature-card img {
  height: 50px; }

.feature-card p {
  margin-bottom: 0; }

.feature-card:last-child {
  border: none; }

/*Secondry Footer*/
.policies-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px 0; }
  .policies-wrapper ul li {
    display: inline-block;
    padding: 0 15px; }
    .policies-wrapper ul li a {
      color: #CBCBCB;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left; }

.container--footer-tertiary {
  background: #fff;
  padding: 30px 15px; }

.payment-images img {
  display: inline-block;
  height: 40px;
  vertical-align: middle;
  margin-right: 5px; }

.app-link-text {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  margin-bottom: 15px; }

.app-links a {
  display: inline-block;
  margin-right: 5px; }

.footer-social-link-wrapper i {
  color: #2875F1; }

.footer-social-link-wrapper a:hover img {
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out; }

/*Quaternary Footer*/
.container--footer-quaternary {
  background: #fff;
  border-bottom: 1px solid #F2F5F6;
  padding: 30px 15px; }

.service-icon .footer-service-icon-wrapper img {
  width: 21%;
  margin-bottom: 1em; }

.service-icon .footer-service-icon-wrapper .service-name {
  font-size: 16px;
  color: #383838;
  letter-spacing: 0;
  text-align: center; }

.service-icon .footer-service-icon-wrapper .service-details {
  font-size: 14px;
  color: #727272;
  letter-spacing: 0;
  text-align: center !important;
  line-height: 20px;
  margin-top: 0.9em;
  text-align: justify;
  max-height: none !important;
  overflow: hidden;
  position: relative;
  line-height: 1.44em;
  max-height: 4.32em; }
  .service-icon .footer-service-icon-wrapper .service-details:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0; }
  .service-icon .footer-service-icon-wrapper .service-details:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.1em;
    background: white; }

.footer_title_text p {
  text-align: justify; }

/*Copyright Segment*/
.copyright {
  background-color: #383838;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  width: 100%;
  padding: 15px 0px; }

.side-menu-container {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  list-style-type: none;
  margin: 20px 0 20px 0; }
  .side-menu-container li .side-menu-img {
    height: 40px;
    width: 40px; }
  .side-menu-container li p {
    font-size: 14px;
    color: #444444; }

.copy-text {
  color: #CBCBCB;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0;
  text-align: center; }

@media (max-width: 576px) {
  .bp3-dialog.dialog-location .bp3-dialog-body {
    margin: 1em 1.5em !important; }
  .bp3-popover.gg-notifications {
    width: 310px !important; }
    .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list {
      max-height: 350px !important; }
      .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .title-head {
        justify-content: flex-start; }
        .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .title-head .title {
          font-size: 13px; }
          .bp3-popover.gg-notifications .bp3-popover-content ul.gg-notifications-list li.gg-notifications-item .title-head .title span.bp3-icon {
            font-size: 16px; }
  .header-bar .bp3-navbar {
    height: 93px; }
    .header-bar .bp3-navbar .bp3-input-group {
      margin-top: 0px !important; }
    .header-bar .bp3-navbar .bp3-navbar-container .retail-header .location {
      font-size: 12px !important;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 500;
      margin-top: 0.5em;
      margin-bottom: 0px; }
    .header-bar .bp3-navbar .bp3-navbar-container .retail-header .location-name {
      font-size: 11px !important;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 100;
      margin-top: 0.1em;
      margin-bottom: 0;
      padding-bottom: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 20px;
      border-bottom: 1px dashed #e8e8e8; }
    .header-bar .bp3-navbar .bp3-navbar-container .retail-header .location-icon {
      color: #fff !important;
      position: absolute;
      top: 44%;
      right: 0;
      left: 80%;
      bottom: 50%; }
      .header-bar .bp3-navbar .bp3-navbar-container .retail-header .location-icon:before {
        font-size: 13px; }
    .header-bar .bp3-navbar .bp3-navbar-container .retail-header .gg-notifications {
      position: relative;
      display: inline-block; }
      .header-bar .bp3-navbar .bp3-navbar-container .retail-header .gg-notifications .badge {
        display: inline-block;
        min-width: 2.1em;
        border-radius: 12%;
        font-size: 0.6rem;
        text-align: center;
        background: #FE6527;
        color: #ffffff;
        padding: 0px 2px !important; }
      .header-bar .bp3-navbar .bp3-navbar-container .retail-header .gg-notifications .badge {
        position: absolute;
        top: -6px;
        right: 1px;
        font-size: .8em; }
  .item_count {
    line-height: 14px !important; }
  .bp3-navbar .bp3-navbar-group li a, ul .bp3-navbar-group li a {
    padding: 0px 5px !important;
    font-size: 12px; }
    .bp3-navbar .bp3-navbar-group li a span, ul .bp3-navbar-group li a span {
      padding: 4px 5px !important;
      font-size: 12px; }
    .bp3-navbar .bp3-navbar-group li a span.bp3-icon:empty, ul .bp3-navbar-group li a span.bp3-icon:empty {
      font-size: 22px;
      padding: 0px 4px !important; }
  .bp3-navbar .bp3-navbar-group .bp3-navbar-heading a .gg-logo, ul .bp3-navbar-group .bp3-navbar-heading a .gg-logo {
    width: 100%; }
  ul.gg-mega-menu {
    max-width: 310px;
    padding: 1em 0em 0.5em 0em !important; }
  .gg-mainmenu-img {
    width: 25%;
    margin-top: 0.6em; }
  .gg-mainmenu-item {
    margin: 3px 0px 12px 0px !important; }
  .awesome-deals .custom-heading {
    font-size: 20px; }
  .awesome-deals .view-all-link {
    font-size: 17px; }
  .awesome-deals .awesome-deals-img:nth-child(even) {
    padding-left: .5rem !important; }
  .awesome-deals .awesome-deals-img:nth-child(odd) {
    padding-right: .5rem !important; }
  .awesome-deals .awesome-dealsbsw-img:nth-child(even) {
    padding-left: .5rem !important; }
  .awesome-deals .awesome-dealsbsw-img:nth-child(odd) {
    padding-right: .5rem !important; }
  .policies-wrapper ul {
    list-style-type: none; }
    .policies-wrapper ul li {
      padding: 5px 15px; }
      .policies-wrapper ul li a {
        font-weight: 500;
        text-align: left;
        display: flex; }
  .footer .container--footer-secondry {
    padding: 25px 10px 25px 10px !important; }
    .footer .container--footer-secondry .bdr-right {
      border-right: none !important; }
    .footer .container--footer-secondry .line-bottom {
      border-bottom: none; }
    .footer .container--footer-secondry .contact {
      margin-top: 2em !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .footer-social-link-wrapper {
      display: flex;
      margin-left: -0.5em;
      float: left !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .address {
      line-height: 16px; }
    .footer .container--footer-secondry .policies-wrapper ul {
      text-align: left; }
      .footer .container--footer-secondry .policies-wrapper ul li {
        padding: 5px 10px !important; }
        .footer .container--footer-secondry .policies-wrapper ul li a {
          font-size: 12px !important; }
    .footer .container--footer-secondry .gg-logo-mobile {
      max-width: 20%;
      float: left;
      display: flex;
      margin-right: 1em;
      margin-top: 1.4em; }
    .footer .container--footer-secondry .footer-parts {
      max-width: 90%;
      float: left; }
      .footer .container--footer-secondry .footer-parts .company-logo {
        max-width: 100% !important;
        flex: 0 0 100% !important; }
      .footer .container--footer-secondry .footer-parts .company-social {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-top: 1.5em !important;
        justify-content: flex-start !important; }
        .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .insta img {
          width: 80% !important; }
        .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .youtube img {
          width: 80% !important; }
        .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .twitter img {
          width: 80% !important; }
        .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .facebook img {
          width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-address {
        max-width: 100% !important;
        flex: 0 0 100% !important; }
        .footer .container--footer-secondry .footer-parts .company-address .address {
          line-height: 16px !important; }
      .footer .container--footer-secondry .footer-parts .company-email {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        align-items: flex-start !important; }
        .footer .container--footer-secondry .footer-parts .company-email .contact {
          margin-bottom: 0px !important; }
      .footer .container--footer-secondry .footer-parts .company-app {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        justify-content: flex-start !important; } }

@media (min-width: 576px) and (max-width: 768px) {
  .item_count {
    line-height: 14px !important; }
  .bp3-navbar .bp3-navbar-group li a, ul .bp3-navbar-group li a {
    padding: 4px 7px !important;
    font-size: 12px; }
    .bp3-navbar .bp3-navbar-group li a span, ul .bp3-navbar-group li a span {
      padding: 4px 5px !important;
      font-size: 12px; }
    .bp3-navbar .bp3-navbar-group li a span.bp3-icon:empty, ul .bp3-navbar-group li a span.bp3-icon:empty {
      font-size: 21px;
      padding: 0px 7px !important; }
  .bp3-navbar .bp3-navbar-group .bp3-navbar-heading a .gg-logo, ul .bp3-navbar-group .bp3-navbar-heading a .gg-logo {
    width: 100%; }
  ul.gg-mega-menu {
    max-width: 320px;
    padding: 1em 0em 0.5em 0em !important; }
  .gg-mainmenu-img {
    width: 25%;
    margin-top: 0.6em; }
  .gg-mainmenu-item {
    margin: 3px 0px 12px 0px !important; }
  .policies-wrapper ul {
    text-align: left;
    list-style-type: none; }
    .policies-wrapper ul li {
      padding: 5px 15px; }
      .policies-wrapper ul li a {
        font-weight: 500;
        text-align: left;
        display: flex; }
  .footer .container--footer-secondry {
    padding: 25px 10px 25px 10px !important; }
    .footer .container--footer-secondry .bdr-right {
      border-right: none !important; }
    .footer .container--footer-secondry .line-bottom {
      border-bottom: none; }
    .footer .container--footer-secondry .contact {
      margin-top: 2em !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .footer-social-link-wrapper {
      display: flex;
      margin-left: -0.5em;
      float: left !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .address {
      line-height: 16px; }
    .footer .container--footer-secondry .policies-wrapper ul {
      text-align: left; }
      .footer .container--footer-secondry .policies-wrapper ul li {
        padding: 5px 10px !important; }
        .footer .container--footer-secondry .policies-wrapper ul li a {
          font-size: 12px !important; }
    .footer .container--footer-secondry .gg-logo-mobile {
      max-width: 20%;
      float: left;
      display: flex;
      margin-right: 1em;
      margin-top: 1.4em; }
    .footer .container--footer-secondry .footer-parts .company-logo {
      max-width: 30% !important;
      flex: 0 0 30% !important; }
    .footer .container--footer-secondry .footer-parts .company-address {
      max-width: 70% !important;
      flex: 0 0 70% !important; }
      .footer .container--footer-secondry .footer-parts .company-address .address {
        line-height: 16px !important; }
    .footer .container--footer-secondry .footer-parts .company-email {
      max-width: 50% !important;
      flex: 0 0 50% !important;
      align-items: flex-start !important; }
      .footer .container--footer-secondry .footer-parts .company-email .contact {
        margin-bottom: 0px !important; }
    .footer .container--footer-secondry .footer-parts .company-social {
      max-width: 50% !important;
      flex: 0 0 50% !important;
      margin-top: 1.5em !important;
      align-items: flex-end;
      justify-content: flex-start !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .insta img {
        width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .youtube img {
        width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .twitter img {
        width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .facebook img {
        width: 80% !important; }
    .footer .container--footer-secondry .footer-parts .company-app {
      margin-top: 2em;
      max-width: 100% !important;
      flex: 0 0 100% !important;
      justify-content: center !important; }
      .footer .container--footer-secondry .footer-parts .company-app .app-links a img {
        width: 85%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .item_count {
    line-height: 14px !important; }
  .bp3-navbar .bp3-navbar-group li a, ul .bp3-navbar-group li a {
    padding: 4px 12px !important;
    font-size: 12px; }
    .bp3-navbar .bp3-navbar-group li a span, ul .bp3-navbar-group li a span {
      padding: 4px 5px !important;
      font-size: 12px; }
    .bp3-navbar .bp3-navbar-group li a span.bp3-icon:empty, ul .bp3-navbar-group li a span.bp3-icon:empty {
      font-size: 21px;
      padding: 0px 0px !important; }
  .bp3-navbar .bp3-navbar-group .bp3-navbar-heading a .gg-logo, ul .bp3-navbar-group .bp3-navbar-heading a .gg-logo {
    padding-top: 0px; }
  ul.gg-mega-menu {
    max-width: 320px;
    padding: 1em 0em 0.5em 0em !important; }
  .gg-mainmenu-img {
    width: 25%;
    margin-top: 0.6em; }
  .gg-mainmenu-item {
    margin: 3px 0px 12px 0px !important; }
  .footer .container--footer-secondry .address {
    line-height: 20px; }
  .footer .container--footer-secondry {
    padding: 25px 10px 25px 10px !important; }
    .footer .container--footer-secondry .bdr-right {
      border-right: none !important; }
    .footer .container--footer-secondry .line-bottom {
      border-bottom: none; }
    .footer .container--footer-secondry .contact {
      margin-top: 2em !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .footer-social-link-wrapper {
      display: flex;
      margin-left: -0.5em;
      float: left !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .address {
      line-height: 16px; }
    .footer .container--footer-secondry .policies-wrapper ul {
      text-align: left; }
      .footer .container--footer-secondry .policies-wrapper ul li {
        padding: 5px 10px !important; }
        .footer .container--footer-secondry .policies-wrapper ul li a {
          font-size: 12px !important; }
    .footer .container--footer-secondry .gg-logo-mobile {
      max-width: 20%;
      float: left;
      display: flex;
      margin-right: 1em;
      margin-top: 1.4em; }
    .footer .container--footer-secondry .footer-parts .company-logo {
      max-width: 20% !important;
      flex: 0 0 20% !important; }
    .footer .container--footer-secondry .footer-parts .company-address {
      max-width: 50% !important;
      flex: 0 0 50% !important; }
      .footer .container--footer-secondry .footer-parts .company-address .address {
        line-height: 16px !important; }
    .footer .container--footer-secondry .footer-parts .company-email {
      max-width: 30% !important;
      flex: 0 0 30% !important;
      align-items: flex-start !important;
      justify-content: flex-end;
      margin-top: -0.5em !important; }
    .footer .container--footer-secondry .footer-parts .company-social {
      max-width: 30% !important;
      flex: 0 0 30% !important;
      margin-top: 1.5em !important;
      align-items: flex-end;
      justify-content: flex-start !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .insta img {
        width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .youtube img {
        width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .twitter img {
        width: 80% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .facebook img {
        width: 80% !important; }
    .footer .container--footer-secondry .footer-parts .company-app {
      margin-top: 2em;
      max-width: 50% !important;
      flex: 0 0 50% !important;
      justify-content: flex-start !important; }
      .footer .container--footer-secondry .footer-parts .company-app .app-links a img {
        width: 85%; } }

@media (min-width: 991px) and (max-width: 1199px) {
  .bp3-navbar .bp3-navbar-group li a, ul .bp3-navbar-group li a {
    padding: 4px 9px !important;
    display: flex; }
    .bp3-navbar .bp3-navbar-group li a span.bp3-icon:empty, ul .bp3-navbar-group li a span.bp3-icon:empty {
      font-size: 21px;
      padding: 0px 0px !important; }
  .bp3-navbar .bp3-navbar-group .bp3-navbar-heading a .gg-logo, ul .bp3-navbar-group .bp3-navbar-heading a .gg-logo {
    width: 100%; }
  .header-bar .gg-location .header-profile-name {
    max-width: 200px;
    font-size: 11.5px; }
  ul.gg-mega-menu {
    max-width: 320px;
    padding: 1em 0em 0.5em 0em !important; }
  .gg-mainmenu-img {
    width: 30%;
    margin-top: 0.6em; }
  .gg-mainmenu-item {
    margin: 3px 0px 12px 0px !important; }
  .footer .container--footer-secondry {
    padding: 35px 20px 35px 20px !important; }
    .footer .container--footer-secondry .line-bottom {
      border-bottom: none; }
    .footer .container--footer-secondry .contact {
      margin-top: 2em !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .footer-social-link-wrapper {
      display: flex;
      margin-left: -0.5em;
      float: left !important;
      justify-content: flex-start; }
    .footer .container--footer-secondry .address {
      line-height: 16px; }
    .footer .container--footer-secondry .policies-wrapper ul {
      text-align: left; }
      .footer .container--footer-secondry .policies-wrapper ul li {
        padding: 5px 10px !important; }
        .footer .container--footer-secondry .policies-wrapper ul li a {
          font-size: 12px !important; }
    .footer .container--footer-secondry .footer-parts .company-logo {
      max-width: 15% !important;
      flex: 0 0 15% !important; }
    .footer .container--footer-secondry .footer-parts .company-address {
      max-width: 30% !important;
      flex: 0 0 30% !important; }
      .footer .container--footer-secondry .footer-parts .company-address .address {
        line-height: 16px !important; }
        .footer .container--footer-secondry .footer-parts .company-address .address b {
          white-space: nowrap; }
    .footer .container--footer-secondry .footer-parts .company-email {
      max-width: 21% !important;
      flex: 0 0 21% !important;
      justify-content: flex-end; }
    .footer .container--footer-secondry .footer-parts .company-social {
      max-width: 19% !important;
      flex: 0 0 19% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .insta img {
        width: 90% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .youtube img {
        width: 90% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .twitter img {
        width: 90% !important; }
      .footer .container--footer-secondry .footer-parts .company-social .footer-social-link-wrapper .facebook img {
        width: 90% !important; }
    .footer .container--footer-secondry .footer-parts .company-app {
      max-width: 15% !important;
      flex: 0 0 15% !important; }
      .footer .container--footer-secondry .footer-parts .company-app .app-links {
        display: block !important; }
        .footer .container--footer-secondry .footer-parts .company-app .app-links a img {
          width: 85%; } }

@media (min-width: 1200px) {
  .bp3-navbar .bp3-navbar-group li a, ul .bp3-navbar-group li a {
    padding: 0px 14px !important;
    display: flex; }
  .bp3-navbar .bp3-navbar-group .bp3-navbar-heading a .gg-logo, ul .bp3-navbar-group .bp3-navbar-heading a .gg-logo {
    width: 100%; }
  ul.gg-mega-menu {
    max-width: 320px;
    padding: 1em 0em 0.5em 0em !important; }
  .gg-mainmenu-img {
    width: 30%;
    margin-top: 0.6em; }
  .gg-mainmenu-item {
    margin: 3px 0px 12px 0px !important; } }

.bp3-portal-gg-login-portal-modal .modal-section {
  width: 350px !important; }
  .bp3-portal-gg-login-portal-modal .modal-section input[type=number]::-webkit-inner-spin-button,
  .bp3-portal-gg-login-portal-modal .modal-section input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .bp3-portal-gg-login-portal-modal .modal-section .bp3-form-content span.error-text {
    display: none; }
  .bp3-portal-gg-login-portal-modal .modal-section .error .bp3-input, .bp3-portal-gg-login-portal-modal .modal-section .error .bp3-input:focus, .bp3-portal-gg-login-portal-modal .modal-section .error .bp3-input:hover, .bp3-portal-gg-login-portal-modal .modal-section .error .bp3-input:active {
    border: 1px solid red !important;
    box-shadow: none; }
  .bp3-portal-gg-login-portal-modal .modal-section .error .error-text {
    margin: 5px 0 0 5px;
    color: red;
    display: block !important;
    text-align: left; }
  .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body {
    margin: 0 !important; }
    .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container input {
      background: #F7FBFF !important;
      border: 1px solid rgba(208, 208, 208, 0.5);
      border-radius: 2.2px;
      height: 50px;
      font-size: 18px; }
    .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container button {
      height: 50px;
      font-size: 18px; }
    .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .bp3-input-group > .bp3-icon:empty {
      line-height: 19px;
      font-size: 21px; }
    .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section {
      background: #FFFFFF;
      border-radius: 5.5px 0px 0px 5.5px; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .login-title {
        font-size: 18px;
        color: #383838;
        letter-spacing: 0;
        text-align: center; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .login-password .login-password-text {
        color: #383838;
        letter-spacing: 2px;
        text-align: left; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .login-remember {
        font-size: 14px !important;
        color: #727272 !important;
        text-align: left;
        line-height: 14px; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .login-btn {
        background: #236AEE;
        border-radius: 2px; }
        .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .login-btn .login-btn-text {
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .forgot-link-btn {
        font-size: 14px !important;
        color: #787E8C !important;
        letter-spacing: 0;
        text-align: center;
        line-height: 14px; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .sign-up-btn {
        font-size: 14px;
        color: #727272;
        letter-spacing: 0;
        text-align: center;
        line-height: 14px; }
        .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .sign-up-btn .sign-up-btn-link {
          font-size: 14px;
          color: #236AEE !important;
          letter-spacing: 0;
          text-align: center;
          line-height: 14px; }
      .bp3-portal-gg-login-portal-modal .modal-section .bp3-dialog-body .login-container .login-section .reset-password-through {
        font-size: 14px;
        color: #727272;
        letter-spacing: 0;
        text-align: center;
        line-height: 18px; }

.bp3-portal-gg-depadancy-portal-modal .bp3-dialog-container .bp3-dialog .bp3-dialog-body {
  margin: -1em 4em 1em 4em; }

.bp3-portal-gg-depadancy-portal-modal .bp3-dialog-container .bp3-dialog .bp3-dialog-header {
  margin: 0.3em 1em 0.3em 0em; }
  .bp3-portal-gg-depadancy-portal-modal .bp3-dialog-container .bp3-dialog .bp3-dialog-header .bp3-heading {
    margin-left: 2em; }

.bp3-portal-gg-depadancy-portal-modal .bp3-select select {
  height: 40px !important;
  background: #fff !important; }

.bp3-portal-gg-depadancy-portal-modal .bp3-select::after {
  line-height: 26px; }

.bp3-portal-gg-depadancy-portal-modal .bp3-form-content span.error-text {
  display: none; }

.bp3-portal-gg-depadancy-portal-modal .bp3-popover-target {
  width: 100%; }

.bp3-portal-gg-depadancy-portal-modal .title {
  color: #383838; }

.bp3-portal-gg-depadancy-portal-modal .error .bp3-input, .bp3-portal-gg-depadancy-portal-modal .error .bp3-input:focus, .bp3-portal-gg-depadancy-portal-modal .error .bp3-input:hover, .bp3-portal-gg-depadancy-portal-modal .error .bp3-input:active {
  border: 1px solid red !important;
  box-shadow: none; }

.bp3-portal-gg-depadancy-portal-modal .error .error-text {
  margin: 5px 0 0 5px;
  color: red;
  display: block !important;
  text-align: left; }

.bp3-portal-gg-depadancy-portal-modal .modal-section {
  width: 420px; }

html {
  scroll-behavior: smooth; }

body {
  scroll-behavior: smooth; }
  body .bp3-overlay-open {
    overflow: auto !important; }

.btn-viewMore:hover .bp3-icon {
  color: #ffffff; }

.location-selector.bp3-dialog-body {
  margin: 20px; }

.location-selector {
  height: 100px !important; }
  .location-selector h3 {
    color: #727272 !important;
    font-size: 1.5em; }
  .location-selector .bp3-input, .location-selector .bp3-input:hover, .location-selector .bp3-input:active {
    border-radius: 4px 4px 0 0; }
  .location-selector .autocomplete-dropdown-container {
    max-height: 40% !important;
    border: 0px;
    overflow: auto;
    position: relative; }
    .location-selector .autocomplete-dropdown-container .suggestion-item {
      padding: 10px 15px;
      border: 1px solid #CBCBCB;
      border-bottom: none !important;
      text-align: left;
      background: #ffffff;
      cursor: pointer; }
      .location-selector .autocomplete-dropdown-container .suggestion-item p {
        color: #383838; }
        .location-selector .autocomplete-dropdown-container .suggestion-item p .bp3-icon {
          color: #727272; }
      .location-selector .autocomplete-dropdown-container .suggestion-item:first-child {
        border-top: none !important; }
      .location-selector .autocomplete-dropdown-container .suggestion-item:last-child {
        border-bottom: 1px solid #CBCBCB !important; }
    .location-selector .autocomplete-dropdown-container .suggestion-item.active {
      background: #f0f0f0; }

.bp3-popover {
  margin-top: 4px; }

.animation-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9); }

.fix-to-bottom {
  position: fixed;
  right: 2%;
  display: none;
  z-index: 9;
  bottom: 1.5em; }
  .fix-to-bottom .bp3-button.bp3-minimal.fix-button, .fix-to-bottom .bp3-button.bp3-minimal.fix-button:hover {
    background: none;
    border: none; }

::-webkit-scrollbar-thumb {
  background: #236aee !important;
  border-radius: 10px; }

::-webkit-scrollbar-track {
  border-radius: 10px; }

::-webkit-scrollbar {
  width: 6px; }

.not-found {
  padding: 5em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 401px); }
  .not-found img {
    width: 100%; }

@media (max-width: 576px) {
  .fix-to-bottom {
    z-index: 9;
    bottom: 1em;
    left: 0%;
    right: auto !important;
    text-align: center;
    margin: 0 auto;
    min-width: 100%;
    max-width: 100%; }
    .fix-to-bottom .bp3-button {
      background: #236AEE !important;
      padding: 4px 8px !important;
      border-radius: 50px !important;
      font-size: 12px;
      background-color: rgba(35, 106, 238, 0.9) !important;
      min-height: 15px !important;
      line-height: 16px !important;
      box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16) !important; }
      .fix-to-bottom .bp3-button p {
        color: #fff;
        margin-bottom: 0;
        font-size: 12px; } }
